@import 'node_modules/knacss/sass/_vendor/reboot';

$hyatt:#2672EC;
$hyatt-h: #1d49ac;
$hyatt-a: #141F6C;


.inte-nav {
  max-width: 424px;
  margin: auto;
  margin-bottom: 120px;

	li {
    list-style: none;
    padding: 6px;

		a {
      display: block;
      text-align: center;
      padding: 12px;
      font-weight: 700;
      text-transform: uppercase;
      background-color: #1f1f1f;
      text-decoration: none;


      &:hover {
        background-color: #eee;
        color: #1f1f1f;
      }

      &:active {
        background-color: white;
        color: #1f1f1f;
      }
		}
	}
}

body {
  background: black;
}

* {
  outline-style: none;
}

h2.title {
  font-size: 16px;
  margin-bottom: 16px;
  @media (min-width: 768px) {
    font-size: 20px;
    margin-bottom: 20px;
  }
}


h2.center-title {
  font-size: 16px;
  margin-bottom: 16px;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  @media (min-width: 768px) {
    font-size: 24px;
    margin-bottom: 24px;
  }
}

img, video {
  max-width: 100%;
}

video {
  display: block;
}

a {
  color: #d2d2d2;
  text-decoration: underline;
  transition: all 0.15s ease-in-out;
}

a:hover {
  text-decoration: none;
  color: $hyatt;
}

a:active {
  text-decoration: none;
  color: $hyatt-h;
}

a.cover {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 35px;
  z-index: 2;
}

button:focus {
  outline: none;
}

button {
  transition: all 0.15s ease-in-out;
  font-family: 'Roboto';
  cursor: pointer;
}


.cat {
  font-weight: 700;
  &.fnb { color: #ff6600; }
  &.ope { color: #76BD87; }
  &.snm { color: #FF7DD7; }
}

button.x-small {
  height: 30px;
  border-radius: 6px;
  padding: 0px 16px 0px 16px;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 700;
}

button.small {
  height: 39px;
  border-radius: 6px;
  padding: 0px 24px 0px 24px;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 700;
}

button.vsmall {
  height: 33px;
  border-radius: 6px;
  padding: 0px 18px 0px 18px;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 700;
}

button.big {
  height: 44px;
  border-radius: 6px;
  padding: 0px 40px 0px 40px;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 700;
}

button.primary {
  border: none;
  background-color: $hyatt;
  color: white;
	transition: opacity 0.15s ease-in-out;
	&:disabled {
		cursor: default;
		pointer-events: none;
		opacity: .6;
	}
  &:hover {
    background-color: $hyatt-h;
  }

  &:active {
    background-color: $hyatt-a;
  }
}

button.secondary {
  background-color: transparent;
  border: 1px solid $hyatt;
  color: $hyatt;

  &:hover {
    border: 1px solid lighten( $hyatt, 5% );
    color: lighten( $hyatt, 5% );
  }

  &:active {
    border: 1px solid lighten( $hyatt, 10% );
  }
}

button.tertiary {
  background-color: #1f1f1f;
  color: white;
  border: none;

  &:hover {
    background-color: lighten( #1f1f1f, 5% );
    color: white;
  }

  &:active {
    background-color: lighten( #1f1f1f, 10% );
    color: white;
  }
}



button.quaternary {
  background-color: transparent;
  border: 1px solid #d2d2d2;
  color: #d2d2d2;

  &:hover {
    border: 1px solid lighten(#d2d2d2, 5% );
    color: lighten(#d2d2d2, 5% );
  }

  &:active {
    border: 1px solid lighten(#d2d2d2, 10% );
    color: lighten(#d2d2d2, 10% );
  }
}



.gradient {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  background: rgba(0,0,0,0);
  background: -moz-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,0) 39%, rgba(0,0,0,1) 98%, rgba(0,0,0,1) 100%);
  background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(0,0,0,0)), color-stop(39%, rgba(0,0,0,0)), color-stop(98%, rgba(0,0,0,1)), color-stop(100%, rgba(0,0,0,1)));
  background: -webkit-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,0) 39%, rgba(0,0,0,1) 98%, rgba(0,0,0,1) 100%);
  background: -o-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,0) 39%, rgba(0,0,0,1) 98%, rgba(0,0,0,1) 100%);
  background: -ms-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,0) 39%, rgba(0,0,0,1) 98%, rgba(0,0,0,1) 100%);
  background: linear-gradient(to bottom, rgba(0,0,0,0) 0%, rgba(0,0,0,0) 39%, rgba(0,0,0,1) 98%, rgba(0,0,0,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#000000', GradientType=0 );
}



.form-item {
  margin-bottom: 16px;
  position: relative;
  label {
    font-family: 'Roboto';
    display: block;
    color: #d2d2d2;
    font-size: 14px;
    margin-bottom: 0;
  }

  input {
    display: block;
    font-size: 14px;
  }

  .search-categories__control,
  .search-categories__menu,
  input[type=text],
  input[type=password] {
    font-family: 'Roboto';
    width: 100%;
    background-color: #1F1F1F;
    border: 1px solid #414141;
    color: #d2d2d2;
    border-radius: 2px;
    height: 44px;
    padding: 10px;
    &:disabled {
      background-color: #1F1F1F;
      color: #656565;
    }
  }

  textarea {
    font-family: 'Roboto';
    width: 100%;
    background-color: #1F1F1F;
    border: 1px solid #414141;
    color: #d2d2d2;
    border-radius: 2px;
    min-height: 120px;
    padding: 10px;
    font-size: 14px;
  }

	.select {
    position: relative;
    &:before {
      display: block;
      width: 56px;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      line-height: 44px;
      text-align: center;
      color: #323232;
      z-index: 1;
      pointer-events: none;
      font-size: 22px;
    }

		select {
      position: relative;
      z-index: 0;
      appearance: none;
      font-family: 'Roboto';
      width: 100%;
      background-color: #151515;
      border: 1px solid #414141;
      color: #d2d2d2;
      border-radius: 2px;
      height: 44px;
      padding: 0 10px;
      padding-right: 56px;
      line-height: 2;
    }

    select::-ms-expand {
      display: none;
    }
  }

  &.disabled {
    .select select {
      background-color: #1F1F1F;
      color: #656565;
      pointer-events: none;
    }
  }
  [type="checkbox"] {
    position: absolute;
    opacity: 0;
  }

  [type="checkbox"] + label {
    display: block;
    padding-left: 26px;
    line-height: 18px;
    font-size: 14px;
    position: relative;
  }

  [type="checkbox"] + label:before {
    font-family: 'icomoon';
    content: "\e915";
    display: block;
    font-size: 18px;
    position: absolute;
    top: 0;
    left: 0;

  }

  [type="checkbox"]:checked + label:before {
    content: "\e916";
    color: $hyatt;
  }


  .file-upload {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-wrap: wrap;

    h2 {
      font-size: 18px;
      font-weight: 600;
      margin-bottom: 36px;
      text-align: center;
    }


    h3 {
      width: 100%;
      margin-bottom: 36px;
      font-weight: 400;
      text-align: center;
      font-size: 14px;
      margin: -36px 0 36px;
    }

    @media (min-width: 768px) {
      flex-direction: row;
      justify-content: center;

      h2 {
        width: 100%;
        font-size: 24px;
      }
    }
  }

  .img-wrapper {
    width: 70px;
    height: 70px;
    position: relative;
    background-image: url('/assets/images/profile.png');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    border-radius: 100%;
    margin-bottom: 40px;

    img {
      flex-shrink: 0;
      object-fit: cover;
      width: 72px;
      height: 72px;
      border: 0;
      margin: -1px;
      max-width: inherit;
    }


    @media (min-width: 768px) {
      width: 120px;
      height: 120px;
      margin-right: 24px;
      margin-bottom: 0px;

      img {
        flex-shrink: 0;
        object-fit: cover;
        width: 122px;
        height: 122px;
        max-width: inherit;
      }
    }
  }

  label.upload {
    display: inline-block;
    cursor: pointer;
    height: 50px;
    font-size: 16px;
    line-height: 20px;
    font-weight: 700;
    font-family: 'Roboto';
    padding: 14px 24px;
    border-radius: 2px;
    color: white;
    text-transform: uppercase;
    border: 1px solid #414141;
    span { margin-right: 4px; }
  }

  &.form-search {
    margin: auto;
    &,
    & + .tags {
      max-width: 823px;
    }

    & + .tags {
      //text-align: left;

      & > .tag {
        margin: 0 0 10px;
        padding: 2px 10px;
        //border-right: 1px solid #717171;
        border-radius: 0;
        font-weight: 500;
        // line-height: 100%;

        &.tag-ur-it {
          background-color: transparent;
          //font-weight: normal;
          //text-decoration: underline;
          background: #2C2C2C;
          border-radius: 30px;
          padding: 2px 10px;
        }

        &:last-child {
          border-right: none;
          //padding-right: 0;
        }
      }
    }

    .search-button {
      position: absolute;
      right: 0;
      height: 40px;
      width: 40px;
      z-index: 2;
      cursor: pointer;

      @media (min-width: 768px) {
        height: 68px;
        width: 68px;
      }
    }

    input {
      height: 40px;
      border-radius: 8px;
      padding-right: 40px;
      padding-left: 18px;
    }

    &:before {
      content: "\e90a";
      font-family: 'icomoon';
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      width: 40px;
      font-size: 16px;
      line-height: 40px;
      text-align: center;
      color: white;
    }

    .search-categories_container {
      margin: 10px 0 0;

      .search-categories__indicator-separator {
        display: none;
      }

      .search-categories__value-container {
        display: block;
      }

      .search-categories__control {
        height: 40px;
        border-radius: 8px;
        padding: 0;
        box-shadow: none;

        &:hover {
          border-color: #414141;
        }

        .search-categories__single-value {
          color: #D2D2D2;
          width: 100%;
          font-size: 14px;
          margin: 0;

          @media (min-width: 1024px) {
            font-size: 18px;
          }
        }

        .search-categories__value-container {
          height: 100%;
          padding: 8px 10px;

          @media (min-width: 768px) {
            padding: 20px 20px;
          }
        }

        .search-categories__input {
          height: 40px;
          margin: 0;
          display: block!important;
        }

        input {
          display: block!important;
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          width: 100%;
          color: #d2d2d2!important;
          grid-area: none!important;
        }
      }
      .search-categories__menu {
        height: auto;
        border-radius: 8px;
        @media (min-width: 1024px) {
          font-size: 18px;
        }

        .search-categories__option--is-focused {
          background-color: #3b3b3b;
          cursor: pointer;
        }
        .search-categories__option--is-selected {
          background-color: #4e4e4e;
          cursor: pointer;
        }
      }
    }

    @media (min-width:768px) {
      margin: 32px auto;

      input {
        height: 68px;
        padding-right: 68px;
        //padding-left: 220px;
        padding-left: 40px;
        font-size: 18px;
      }

      .search-categories_container {
        position: absolute;
        margin: 0;
        top: 1px;
        width: 200px;
        height: 66px;
        left: 1px;

        .search-categories__control {
          border: none;
          height: 66px;
        }
      }

      &:before {
        width: 68px;
        line-height:68px;
        text-align: center;
        color: white;
        font-size: 24px;
      }
    }
  }
}

.search {
  .viewport {
    @media (max-width: 767px) {
      padding-top: 0;
    }
    @media (min-width: 768px) {
      padding-bottom: 0;
    }
  }

  .filter-wrapper {
    text-align: right;
    margin-top: 40px;

    .title {
      float: left;
      font-weight: 700;
      font-size: 20px;
      line-height: 24px;

      color: #FFFFFF;
    }

    .filter {
      width: 240px;
      display: flex;
      justify-content: flex-end;

      font-weight: 500;
      font-size: 11px;
      line-height: 23px;
      color: rgba(255, 255, 255, 0.5);

      margin-right: 0;
      margin-left: auto;

      .icon-sorting {
        font-size: 14px;
      }

      .text {
        white-space: nowrap;
        margin-right: 12px;
      }
    }

    .form-filter {
      margin-bottom: 0;

      input {
        height: 40px;
        border-radius: 8px;
        padding-right: 40px;
        padding-left: 18px;
      }

      .search-categories_container {
        &.category {
          .search-categories__menu {
            .search-categories__option {
              &:nth-of-type(1) {
                color: #FF6600 !important;
              }
              &:nth-of-type(2) {
                color: #76BD87 !important;
              }
              &:nth-of-type(3) {
                color: #FF7DD7 !important;
              }
            }
          }

          &.fnb {
            .search-categories__single-value {
              color: #ff6600;
              font-size: 17px;
            }
          }
          &.ope {
            .search-categories__single-value {
              color: #76BD87;
              font-size: 17px;
            }
          }
          &.snm {
            .search-categories__single-value {
              color: #FF7DD7;
              font-size: 17px;
            }
          }
        }

        .search-categories__indicator-separator {
          display: none;
        }

        .search-categories__value-container {
          display: block;
          overflow: visible;
        }

        .search-categories__control {
          height: 23px;
          box-shadow: none;
          border: none;
          background: #000;
          cursor: pointer;

          padding: 0;

          &:hover {
            border-color: #414141;
          }

          .search-categories__single-value {
            font-weight: 500;
            font-size: 17px;
            line-height: 23px;

            text-align: right;

            color: #FFFFFF;
          }

          .search-categories__value-container {
            height: 100%;
            padding: 0;
          }

          .search-categories__input {
            height: 40px;
            margin: 0;
            display: block!important;
          }

          .search-categories__indicators {
            display: none;
          }

          input {
            display: block!important;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            width: 100%;
            color: #d2d2d2!important;
            grid-area: none!important;
          }
        }
        .search-categories__menu {
          position: absolute;
          right: 0;
          width: 110px;
          height: auto;

          border: none;
          border-radius: 8px;

          text-align: left;
          overflow: hidden;

          padding: 0;

          @media (min-width: 1024px) {
            font-size: 18px;
          }

          .search-categories__menu-list {
            padding: 0;
          }

          .search-categories__option {
            font-weight: 400;
            font-size: 17px;
            line-height: 19px;

            padding: 8px 12px;

            border-bottom: 1px solid #414141;

            color: #FFFFFF;
          }

          .search-categories__option--is-focused {
            background-color: #3b3b3b;
            cursor: pointer;
          }
          .search-categories__option--is-selected {
            background-color: #4e4e4e;
            cursor: pointer;
          }
        }
      }

      @media (min-width:768px) {
        input {
          height: 68px;
          padding-right: 68px;
          //padding-left: 220px;
          padding-left: 40px;
          font-size: 18px;
        }

        .search-categories_container {
          margin: 0;
        }

        &:before {
          width: 68px;
          line-height:68px;
          text-align: center;
          color: white;
          font-size: 24px;
        }
      }
    }
  }

  .categories_container {
    // display: flex;
    // flex-direction: row;
    // justify-content: center;
    //grid of 4 columns
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    max-width: 1000px;
    margin: auto;

    &.is-fb {
      grid-template-columns: repeat(4, 1fr);
    }

    @media (max-width: 975px) {
      grid-template-columns: repeat(2, 1fr) !important;
      max-width: 100%;
    }
    @media (max-width: 590px) {
      grid-template-columns: repeat(1, 1fr) !important;
      max-width: 100%;
    }

    margin-top: 0;
    margin-bottom: 40px;

    @media (min-width: 768px) {
      margin-top: 14px;
      margin-bottom: 0;
    }

    .category {
      display: flex;
      justify-content: center;
      align-items: center;
      // width: 33%;

      font-weight: 400;
      font-size: 14px;
      line-height: 23px;

      text-align: center;
      text-transform: uppercase;

      color: #FFFFFF;

      text-shadow: 0px 4px 8px rgba(0, 0, 0, 0.45);

      cursor: pointer;

      padding-top: 24px;
      padding-bottom: 24px;

      cursor: pointer;

      .untaken-num {
        display: inline-block;
        min-width: 23px;
        height: 23px;

        font-weight: 700;
        font-size: 14px;
        line-height: 23px;

        color: #FFFFFF;

        border-radius: 20px;
        background: var(--border-color);

        padding: 0 5px 0 7px;
        margin-left: 8px;
      }

      @media (min-width:768px) {
        width: auto;

        font-weight: 500;
        font-size: 24px;
        line-height: 29px;
        text-align: center;
        letter-spacing: 0.1em;

        padding: 24px 40px;
      }

      &.active {
        border-bottom: 4px solid var(--border-color);
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;

        @media (min-width:768px) {
          font-weight: 700;
          font-size: 24px;
          line-height: 29px;
        }
      }
    }
  }
}

.empty {
  text-align: center;
  margin: 48px 0;
}

.form-actions {
  text-align: center;
  margin-top: 40px;
  button {
    width: auto;
    margin: 0 8px;
  }
}

body.signin {
  .form-actions {
    margin-top: 24px;
    button {
      width: 100%;
    }
  }
}

body.popup-opened {
  overflow: hidden;
  .popup-wrapper {
    display: flex;
  }
}
.popup-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba($color: #000000, $alpha: 0.5);
  z-index: 6;
  display: none;
  padding: 16px;
  align-items: center;
  justify-content: center;
  overflow: auto;
  flex-wrap: wrap;


  .popup-inner {
    background: #1D1D1D;
    border: 1px solid #414141;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.12);
    border-radius: 6px;
    width: 100%;
    overflow: hidden;

		.popup-title {
      background: #262626;
      box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.12);
      border-radius: 6px 6px 0px 0px;
      padding: 16px 24px;
      padding-right: 54px;
      position: relative;
      .close {
        font-size: 16px;
        position: absolute;
        top: 0;
        right: 0;
        width: 54px;
        height: 54px;
        line-height: 54px;
        text-align: center;
        cursor: pointer
      }
			h2 {
        font-weight: 600;
        font-size: 18px;
        line-height: 18px;
        margin-bottom: 5px;
        min-height: 18px;;
			}
		}

		.close {

		}
	}

	.popup-content {
    padding: 40px 24px;
		p {
      font-size: 18px;
      color: #c0c0c0;
			br {

			}
		}

		.popup-action {
      text-align: center;
      margin-top: 40px;
			.primary {

			}
		}
  }

  &.message .popup-inner {
    max-width: 650px;

    .popup-content { text-align: center; }
  }

  &.suggestion .popup-inner {
    max-width: 650px;
  }

  &.registration .popup-inner {
    max-width: 650px;

    form {
      h2 {
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        text-align: center;
      }

      p {
        font-size: 14px;
        line-height: 24px;
        text-align: center;
      }
    }
  }

  &.profile-pic .popup-inner {
    max-width: 650px;
  }

  &.announcement .new-popup-inner {
    max-width: 750px;
    max-height: 75vh;
    width: 90%;

    box-shadow: 0px 8px 16px rgb(0, 0, 0 / 12%);
    border-radius: 6px;
    overflow: hidden;
    background: #1D1D1D;
    border: 1px solid #414141;

    & .new-popup-title {
      background-color: #262626;
      text-align: center;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 16px 20px;

      & h2 {
        font-weight: 600;
        font-size: 18px;
        line-height: 18px;
        margin-bottom: 5px;
        min-height: 18px;

        & .icon-bell {
          position: relative;
          top: 3px;
          font-size: 22px;
          line-height: 0px;
        }     
      }
    }

    & .new-anouncement-text {
      color: #d2d2d2;
      padding: 40px 24px;
      text-align: center;
      overflow-y: auto;
      max-height: calc(75vh - 55px);
      // margin-right: -15px;
      // padding-right: calc(24px + 15px);

      & h2 {
        font-size: 18px;
        text-align: center;
        margin-bottom: 12px;

        @media (min-width: 768px) {
          font-size: 21px;
        }
      }

      & p {
        font-size: 16px;

        @media (min-width: 768px) {
          font-size: 18px;
        }
      }
    }

    & .new-anouncement-img {
      width: 100%;
      max-height: calc(75vh - 55px);
      object-fit: contain;
    }
  }

  // &.announcement .popup-inner {
  //   // max-width: 426px;

  //   max-width: 750px;
  //   width: 90%;


	// 	.popup-title {
  //     text-align: center;

  //     span {
  //       position: relative;
  //       top: 3px;
  //       font-size: 22px;
  //       line-height: 0px;
  //     }
  //   }

  //   .popup-content {
  //     padding: 0;
  //       width: 100%;
  //       height: 75vh;
  //   }

  //   .anouncement-img {
  //     // margin: -40px -24px;
  //     height: 100%;
  //   max-height: 75vh;
  //       background-size: contain;
  //       background-repeat: no-repeat;
  //       background-position: center;
  //     img {
  //       width: 100% !important;
  //       height: 100% !important;
  //       // max-height: 70vh;
  //       object-fit: contain;

  //     }
  //   }

  //   .anouncement-text {
  //     color: #d2d2d2;
  //     padding: 40px 24px;
  //     text-align: center;
  //     h2 {
  //       text-align: center;
  //       font-size: 18px;
  //       margin-bottom: 12px;
  //       &:last-child { margin-bottom: 0;}

  //       @media (min-width: 768px) {
  //         font-size: 21px;
  //       }
  //     }

  //     p {
  //       font-size: 16px;
  //       margin-bottom: 24px;
  //       &:last-child { margin-bottom: 0;}

  //       @media (min-width: 768px) {
  //         font-size: 18px;
  //       }
  //     }
  //   }
  // }
}


body.front header .left .home { display: none; }

header {
  position: fixed;
  height: 88px;
  left: 0;
  right: 0;
  top: 0;
  z-index: 3;
  transform: translateX(0%);
  transition: transform 0.4s cubic-bezier(0.65, 0.05, 0.36, 1), height 0.15s ease-in-out;
  background: #000;

  .scrolled & {
    height: 74px;
  }

  @media (min-width: 1150px) {
    height: 88px;

    .scrolled & {
      height: 68px;
    }
  }

  .menu-opened & {
    transform: translateX(100%);
  }

  @media (max-width: ($notificationBreakpoint - 1)) {
    .notifications-opened & {
      transform: translateX(-100%);
    }
  }

  // .second-logo {
  //   font-size: 29px !important;
  //   display: none !important;

  //   @media (max-width: 1150px) {
  //     display: none !important;
  //   }

  //   @media (min-width: 1150px) {
  //     display: block !important;
  //   }

  //   @media (min-width: 1550px) {
  //     display: none !important;
  //   }
  // }

  &:after {
    content: '';
    position: absolute;
    left: 15px;
    right: 15px;
    bottom: 0;
    border-top: 1px solid #1C1C1C;
  }

  a {
    text-decoration: none !important;
  }

  a.logo {
    color: white;
    display: block;
    position: absolute;
    top: 50%;
    left: 20px;
    transform: translateY(-50%);
    text-decoration: none;
    line-height: 74px;
    font-size: 46px;

    .scrolled & {
      font-size: 35px;
    }


    // @media (max-width: 1150px) {
    //   display: block;
    // }

    @media (min-width: 1150px) {
      // display: none;
      left: 50%;
      transform: translate(-50%,-50%);

      font-size: 80px;

      .scrolled & {
        font-size: 48px;
      }
    }
    // @media (min-width: 1550px) {
    //   display: block;
    // }
  }

  .left,
  .right {
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 1;
    display: flex;
    padding: 0 8px;

    a, .burger {
      //height: 100%;
      display: block;
      font-size: 14px;
      line-height: 19px;
      color: white;
      padding: 30px 10px;
      cursor: pointer;
      transition: padding 0.15s ease-in-out;
      position: relative;

      @media (min-width: 1150px) {
        font-size: 14px;
        //padding: 28px 8px 20px;
        //padding: 8px 20px;
        padding: 8px 6px;
        margin: 30px 6px 22px;

        .scrolled & {
          margin: 20px 6px 12px;
        }

        &:has(span) {
          //padding: 8px 12px 8px 20px;
          padding: 8px 4px 8px 12px;
        }

        &.active {
          background: #1D1D1D;
          border-radius: 8px;
        }
      }

      @media (min-width: 1400px) {
        padding: 8px 20px;
        &:has(span) {
          padding: 8px 12px 8px 20px;
        }
      }
    }

    @media (min-width: 1150px) {
      font-size: 20px;
      padding: 0;
    }
    @media (min-width: 1300px) {
      padding: 0 54px;
    }
  }

  .icon {
    font-size: 20px !important;
  }

  .icon-search {
    font-size: 13px;
    line-height: 19px;

    margin-left: 10px;
  }

  .left {
    left: 0;

    .home {
      position: relative;
      padding-right: 0;

      span {
        font-family: 'Montserrat';
        font-size: 14px;
        font-weight: 400;
        display: block;
        -webkit-font-smoothing: auto;
        text-transform: uppercase;
      }
    }

    @media (max-width: 1150px) {
      .home span {
        display: block;
        position: absolute;
        top: 50%;
        font-size: 8px;
        margin-top: 2px;
        left: 0;
        right: 0;
        text-align: center;
      }
    }

    @media (min-width: 1151px) {
      .home:before {
        position: absolute;
      }
      .home span {
        position: relative;
        margin-left: 3px;
        text-transform: inherit;
        padding-left: 24px;
      }
    }
  }

  .right {
    right: 0;

    a,
    .burger {
      padding: 4px 12px;
      margin: 31px 6px 25px;

      .scrolled & {
        margin: 24px 6px 18px;
      }

      &:has(span) {
        padding: 4px 12px;
      }

      @media (min-width: 1150px) {
        margin: 34px 6px 28px;

        .scrolled & {
          margin: 24px 6px 18px;
        }
      }
    }
  }

  .nav-menu {
    @media (min-width: 1151px) {
      .visible-md {
        display: none;
        pointer-events: none;
      }
    }

    @media (max-width: 1150px) {
      .hidden-md {
        display: none;
        pointer-events: none;
      }
    }
  }

  .extra-text {
    position: absolute;
    top: 88px;
  }

  .nav-wrapper {
    position: fixed;
    background-color: rgba($color: #000000, $alpha: 0.5);
    top: 0;
    left: -100%;
    width: 100%;
    height: 100vh;
    z-index: 2;
    transition: opacity 0.15s ease-in-out;

    .nav-inner {
      background-color: black;
      width: 100%;
      background-color: #1d1d1d;
      height: 100%;
      padding: 32px;
      position: relative;

      .close {
        font-size: 22px;
        width: 72px;
        height: 88px;
        line-height: 88px;
        text-align: center;
        position: absolute;
        top: 0;
        right: 0;
        cursor: pointer;
      }

      h2 {
        font-size: 20px;
        line-height: 30px;
        color: white;
        text-transform: uppercase;
        margin-top: 40px;
        margin-bottom: 24px;
        font-weight: 600;
        letter-spacing: 0.15em;
        text-align: center;
      }

      ul {
        padding: 0;

        li {
          list-style: none;
          border-bottom: 1px solid #414141;

          &:last-child {
            border-bottom: none;
          }

          a {
            color: white;
            font-size: 18px;
            font-weight: 600;
            color: #d2d2d2;
            line-height: 48px;
            text-transform: uppercase;

            .scrolled {

            }

            &:hover, &:active {
              color: white;
            }
          }
        }
      }

      a.logout {
        display: none;
        bottom: 24px;
        text-align: center;
        left: 24px;
        right: 24px;
        font-weight: 600;
        font-size: 14px;
        line-height: 24px;
        text-transform: uppercase;
        margin-top: 20px;

        span {
          font-weight: normal;
          text-transform: none;
        }

        &:active, &:hover {
          color: white;
        }

        @media (max-width: 1150px) {
          display: block;
        }
      }
    }
  }

  @media (min-width: $notificationBreakpoint) {
    .notifications {
      position: relative;
    }
  }

  .icon-burger.red,
  .icon-bell.red {
    &:after {
      content: '';
      display: block;
      position: absolute;
      width: 6px;
      height: 6px;
      background-color: #EE2E24;
      border-radius: 100%;
      top: 50%;
      left: 50%;
      border: 1px solid #fff;
      outline: 2px solid #000;
      margin-top: -10px;
      margin-left: 2px;
    }
  }

  .icon-burger.red:after {
    outline: none;
    margin-top: -13px;
    margin-left: 7px;
  }

  .nav-wrapper.notifications {
    left: 100%;

    .layer-notif {
      position: fixed;
      height: calc( 100vh - 88px );
      width: 100vw;
      margin-top: 88px;
      top: 0;
      left: 0;

      .scrolled & {
        height: calc( 100vh - 64px );
        margin-top: 64px;
      }

      @media (max-width: 860px) {
        display: none;
      }
    }

    .nav-inner {
      padding: 32px 0;

      h2 {
        text-align: left;
        padding-left: 24px;
        text-transform: none;
        letter-spacing: 0;
        font-size: 18px;
        margin-bottom: 0;
        padding-bottom: 24px;
        border-bottom: 1px solid #353535;
      }

      .item-wrapper {
        position: absolute;
        top: 127px;
        bottom: 0;
        overflow: scroll;
        width: 100%;
        left: 0;
      }
    }

    @media (min-width: $notificationBreakpoint) {
      position: absolute;
      width: 425px;
      top: 68px;
      right: 0;
      left: auto;
      max-height: 430px;
      height: 75vh;

      @supports (height: 75svh) {
        height: 75svh; 
      }

      background: transparent;
      display: none;

      .notifications-opened & {
        display: block;
      }

      .close {
        display: none;
      }

      &:before {
        content: '';
        display: block;
        background-color: #1D1D1D;
        border-top: 1px solid #414141;
        border-left: 1px solid #414141;
        position: absolute;
        top: -5px;
        right: 15px;
        transform: rotate(45deg);
        height: 10px;
        width: 10px;
        z-index: 1;
      }

      .nav-inner {
        padding-top: 0;
        overflow: hidden;
        border: 1px solid #414141;
        border-radius: 6px;
        box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.12);

        .item-wrapper {
          top: 79px;
        }
      
        h2 {
          padding: 24px;
          margin: 0;
        }
      }
    }

    .item {
      padding: 16px 135px 16px 25px;
      border-bottom: 1px solid #353535;
      position: relative;

      @media (min-width: $notificationBreakpoint) {
        padding: 16px 135px 16px 24px;
      }

      h3 {
        font-size: 12px;
        text-transform: uppercase;
        line-height: 14px;
        font-family: Roboto;
        font-weight: 700;
        margin-top: 2px;
        color: #fff;
      }

      p, div {
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 18px;
        margin: 0;
        color: #D2D2D2;
      }

      div {
        color: #717171;
      }

      a {
        position: absolute;
        right: 10px;
        top: 0;
        font-size: 12px;
        padding: 15px;
        top: 50%;
        line-height: 100%;
        margin-top: -21px;
        text-transform: uppercase;
        height: auto;
        font-weight: bold;
      }

      &.unread {
        background-color: #414141;

        h3:before {
          content: '';
          display: block;
          height: 5px;
          width: 5px;
          background-color: #337AE4;
          top: 23px;
          left: 13px;
          position: absolute;
          border-radius: 100%;
        }
      }
    }
  }

  .tab-select-wrapper {
    .icon-chevronright2 {
      margin-left: 8px;
    }

    .tab-name {
      padding: 20px;

      @media (min-width: 1150px) {
        padding: 0;
      }
    }

    .tab-select {
      display: none;

      &.tab-opened {
        position: relative;
        top: 0;
        left: 0;
        width: 100%;

        display: block;
        background: #1D1D1D;
        filter: drop-shadow(0px 4px 6px rgba(0, 0, 0, 0.15));

        border-radius: 8px;

        @media (min-width: 1150px) {
          position: absolute;
          top: 40px;

          border: 1px solid #000000;
        }

        .tab {
          display: flex;
          justify-content: center;
          padding: 12px;
          margin: 0;
          
          @media (min-width: 1150px) {
            justify-content: flex-start;
            padding: 8px 12px;
          }

          & + .tab {
            border-top: 1px solid #414141;
          }

          img {
            width: 20px;
            height: 20px;
            margin-right: 8px;
          }

          span {
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;

            color: #FFFFFF;
          }
        }
      }
    }

    .icon-logout {
      font-size: 12px !important;
      line-height: 19px;
      margin-left: 13px;
    }

    &.languages-select.burger {
      min-width: 100px;

      @media (min-width: 1150px) {
        background: #1D1D1D;
        border-radius: 8px;
      }

      .icon-chevronright2 {
        @media (min-width: 1150px) {
          position: absolute;
          top: 50%;
          right: 12px;
          transform: translateY(-50%);
        }
      }
    }

    &.profile-select {
      &.burger {
        padding: 0;
      }

      .profile-info {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 8px;
        padding: 20px;

        border-top: 1px solid #1D1D1D;

        @media (min-width: 1150px) {
          padding: 0;
          border: none;
        }

        .avatar {
          position: relative;
          width: 24px;
          height: 24px;

          .medias{
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;

            span {
              display: block;
              height: 100%;
              width: 100%;
              border-radius: 100%;
              background-size: cover;
              background-position: center;
            }
          }
        }
        .username {
          position: relative;
        }
      }

      .tab-select {
        @media (min-width: 1150px) {
          max-width: 120px;
        }
      }
    }

    &.categories-select-wrapper {
      .tab-name {
        position: relative;
      }
      &.active {
        @media (min-width: 1150px) {
          width: 119px;

          .icon-chevronright2 {
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
          }
        }
      }
    }
  }

  .search-wrapper {

    .search-container {
      display: none;

      &.tab-opened {
        position: fixed;
        top: 74px;
        left: 0;
        width: 100%;
        height: calc(100vh - 74px);
        overflow-y: auto;

        display: block;
        background: #000000;
        filter: drop-shadow(0px 4px 6px rgba(0, 0, 0, 0.15));

        border-radius: 8px;
        padding: 12px 30px;

        @media (min-width: 1150px) {
          position: absolute;
          top: 40px;
          left: 50%;
          transform: translateX(-50%);
          width: 350px;
          height: auto;

          background: #1D1D1D;

          border: 1px solid #000000;
          padding: 16px;
        }
      }

      .form-item.form-search {
        margin: 0;

        &:before {
          content: "";
        }

        input {
          height: unset;

          font-weight: 400;
          font-size: 14px;
          line-height: 23px;

          color: #FFFFFF;
          padding: 8px 40px 8px 12px;
        }

        .icon-search,
        .icon-x {
          position: absolute;
          top: 50%;
          right: 8px;
          transform: translateY(-50%);
          width: 20px;

          font-size: 20px;
          line-height: 23px;
        }

        .search-button {
          width: unset;
          height: unset;
        }
      }

      .types-container {
        margin: 16px 0 20px 0;

        .type {
          position: relative;

          font-weight: 400;
          font-size: 14px;
          line-height: 19px;

          color: #FFFFFF;

          padding: 8px 0;

          border-bottom: 1px solid #1D1D1D;

          @media (min-width: 1150px) {
            border: none;
          }

          &:before {
            content: "\e914";
            font-family: 'icomoon';
            display: block;
            position: absolute;
            top: 50%;
            right: 0;
            transform: translateY(-50%);
            font-size: 12px;
            text-align: center;
            color: white;
          }
        }
      }

      .title-container {
        position: relative;
        padding: 0 8px;
        margin-bottom: 16px;

        .title {
          font-weight: 700;
          font-size: 16px;
          line-height: 23px;

          color: #FFFFFF;
        }

        .icon-x {
          position: absolute;
          top: 50%;
          right: 8px;
          transform: translateY(-50%);
          font-size: 13px;
        }
      }

      .categories-select {
        display: flex;
        margin-bottom: 6px;

        .category {
          width: 72px;

          font-weight: 700;
          font-size: 11px;
          line-height: 13px;
          text-align: center;

          color: #D2D2D2;

          border-bottom: 1px solid #414141;
          padding-bottom: 4px;

          &.fnb {
            color: #ff6600;
            border-bottom: 1px solid #ff6600;
          }
          &.ope {
            color: #76BD87;
            border-bottom: 1px solid #76BD87;
          }
          &.snm {
            color: #FF7DD7;
            border-bottom: 1px solid #FF7DD7;
          }
        }
      }

      .results-container {
        position: relative;
        left: -16px;
        width: calc(100% + 32px);
        max-height: calc(100vh - 300px);
        overflow-y: auto;

        @media (min-width: 1150px) {
          height: auto;
          max-height: 500px;
        }

        .result-item {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 8px 16px;
          margin: 0;

          .image {
            position: relative;
            width: 40px;
            height: 40px;
            border-radius: 8px;

            .medias {
              position: absolute;
              top: 0;
              left: 0;
              right: 0;
              bottom: 0;

              span {
                display: block;
                height: 100%;
                width: 100%;
                border-radius: 8px;
                background-size: cover;
                background-position: center;
              }
            }
          }

          .info {
            width: calc(100% - 40px - 12px);

            .title,
            .description {
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 1;
            }

            .title {
              font-weight: 600;
              font-size: 14px;
              line-height: 17px;

              color: #FFFFFF;

              margin-bottom: 4px;
            }

            .description {
              font-weight: 500;
              font-size: 10px;
              line-height: 12px;

              color: #D2D2D2;
            }
          }
        }
      }

      .explore-button,
      .discover-button {
        font-weight: 400;
        font-size: 12px;
        line-height: 23px;

        text-align: center;
        color: #FFFFFF;

        background: #1F1F1F;

        border: 1px solid #717171;
        border-radius: 8px;

        padding: 4px;
        margin: 0;
      }

      .discover-button {
        padding: 8px;
        margin: 10px 24px;
      }
    }
  }

  .nav-mobile-wrapper {
    display: none;

    &.menu-opened {
      position: fixed;
      top: 74px;
      left: 0;
      width: 100%;
      display: block;

      background: #000000;

      text-align: center;

      @media (min-width: 1150px) {
        display: none;
      }

      .notifications {
        .burger {
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .icon-bell {
          position: relative;
          margin-right: 8px;
        }
      }
    }

    .tab-select-wrapper {
      padding: 0 !important;
      margin: 0;

      &.active {
        background: #1F1F1F;

        .tab-name,
        .profile-info {
          border-bottom: 1px solid #414141;
        }
      }

      .tab-name {
        border-bottom: 1px solid #1D1D1D;

        &.language-tab {
          border-top: 1px solid #1D1D1D;
        }
      }
    }
  }

  .visible-md.tab-name {
    .icon-search {
      font-size: 26px;
    }
  }
}

body:not(.notifications-opened) .nav-mobile-wrapper.menu-opened {
  overflow-y: auto;
  height: calc(100vh - 74px);
  padding-bottom: 100px;
}


footer {

	.logo-wrapper {
    margin: 0 15px;
    padding-top: 40px;
    padding-bottom: 30px;
    border-top: 1px solid #1C1C1C;

		.hyatt {
      font-size: 21px;
      margin-bottom: 16px;
		}

		.ideas {
      font-size: 34px;
		}
	}

	.suggestions {
    text-transform: uppercase;
		p {
			a {
        font-weight: 700;
        text-decoration: underline;
        &:hover { color: #d2d2d2; }
			}
		}
	}
}




footer {
  text-align: center;
  padding-bottom: 60px;
  max-width: 1310px;
  margin: 32px auto 0;

	.logo-wrapper {
    margin: 0 15px;
    padding-top: 40px;
    padding-bottom: 48px;
    border-top: 1px solid #1C1C1C;
    @media (min-width: 768px) {
      padding-top: 60px;
      padding-bottom: 38px;
    }

		.hyatt {
      font-size: 21px;
      margin-bottom: 16px;

      @media (min-width: 768px) {
        font-size: 33px;
        margin-bottom: 24px;
      }
		}

		.ideas {
      font-size: 34px;

      @media (min-width: 768px) {
        font-size: 62px;
      }
		}
	}

	.suggestions {
    text-transform: uppercase;
    font-size: 12px;
		p {
			a {
        font-weight: 700;
        text-decoration: underline;
        color: white;
        &:hover { color: #d2d2d2; }
			}
		}

    @media (min-width: 768px) {
      font-size: 16px;
      line-height: 2;
    }
	}

	.terms {
    font-size: 10px;
    text-transform: uppercase;
    &:active { color: #d2d2d2 !important; }
    &:hover { color: #d2d2d2 !important; }
    @media (min-width: 768px) {
      font-size: 12px;
      line-height: 3;
    }
	}
}

.terms-content {
  font-size: 12px;
  text-align: justify;
  padding: 50px;
  color: rgb(210, 210, 210);
  h1 {
    text-align: center;
    margin-bottom: 20px;
  }
  .address {
    margin-bottom: unset;
    // text-indent: 4%;
  }
}

.welovemondays {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  max-width: 516px;
  margin-bottom: 25px;

  @media only screen and (min-width: 768px) {
    //max-width: 516px;
    margin-bottom: 40px;
  }
}

.back-button {
  padding: 16px;
  display: inline-block;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  cursor: pointer;

  @media only screen and (min-width: 1024px) {
    font-size: 14px;
    margin-left: 50px;
  }

  span {
    font-size: 16px;
    display: inline-block;
    position: relative;
    top: 2px;
  }
}

.mood-tags-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 38px 0px;
  gap: 16px;

  .title {
    font-weight: 600;
    font-size: 28px;
    // white-space: break-spaces;
  }

  .text {
    font-size: 14px;
    text-align: center;
    max-width: 860px;
    // white-space: break-spaces;

    a {
      font-weight: bold;
      text-decoration: underline;

      &:hover {
        color: #FF6600;
      }
    }
  }

  .tags-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    max-width: 930px;
    gap: 22px;

    .tag {
      color: rgb(255, 255, 255);
      padding: 4px 22px;
      border-radius: 40px;
      background-color: rgba(44, 44, 44, 0.6);
      font-size: 17px;
      font-weight: 400;
      border-width: 1px;
      border-style: solid;
      border-color: transparent;
      transition: all 0.3s ease 0s;
      cursor: pointer;

      &:hover {
        background-color: rgb(44, 44, 44);
      }

      &.active {
        // font-weight: 600;
        border-color: rgb(255, 255, 255);
      }
    }
  }
}

.masonry-child {
  position: relative;
  cursor: pointer;

  .tag {
    position: absolute;
    z-index: 1;
    color: #fff;
    padding: 8px 25px;
    min-width: 125px;
    border-radius: 40px;
    font-size: 12px;
    font-weight: 600;
    top: 16px;
    left: 16px;
    text-align: center;
    background-color: rgba(44, 44, 44, 0.6);
  }

  .wrapper-img {
    border-radius: 16px;
    overflow: hidden;

    > img {
      min-height: 66px;
      transition: transform 4s;

      &:hover {
        transition: transform 1s;
        transform: scale(1.015);
      }
    }
  }
}

.mood-popup {
  .close {
    position: absolute;
    top: 24px;
    right: 32px;
    font-size: 50px;
    cursor: pointer;
  }

  .wrapper {
    max-height: 75vh;

    @supports (max-height: 75svh) {
      max-height: 75svh;
    }

    position: relative;

    .tag {
      position: absolute;
      z-index: 1;
      color: #fff;
      padding: 8px 25px;
      min-width: 125px;
      border-radius: 40px;
      font-size: 12px;
      font-weight: 600;
      top: 16px;
      left: 16px;
      text-align: center;
      background-color: rgba(44, 44, 44, 0.6);
    }

    .image {
      border-radius: 16px;
      max-height: 75vh;

      @supports (max-height: 75svh) {
        max-height: 75svh;
      }
    }
  }
}

.recipe-popup {
  align-items: unset;

  .close {
    position: absolute;
    top: 24px;
    right: 32px;
    font-size: 50px;
    cursor: pointer;

    @media (max-width: 768px) {
      top: unset;
      right: unset;
      bottom: 24px;
      font-size: 32px;
    }
  }

  .wrapper {
    position: relative;
    border-radius: 24px;
    border: 1px solid #FF6600;
    background-color: #1F1F1F;
    width: 100%;
    max-width: 1300px;
    height: calc((100vw - 34px) * 0.5625);
    max-height: 731.25px;
    display: flex;
    flex-direction: row;
    overflow: hidden;
    margin-top: 3.5vh;

    @supports (margin-top: 3.5svh) {
      margin-top: 3.5svh;
    }

    &.full {
      .ingredients-wrapper {
        display: none;

        @media (max-width: 768px) {
          display: flex;
        }
      }

      .arrow {
        display: none;
      }

      .button {
        right: 21px;
        opacity: 0.5;

        &:hover {
          opacity: 1;
        }
      }
    }

    @media (max-width: 768px) {
      flex-direction: column;
      height: 75vh;

      @supports (height: 75svh) {
        height: 75svh;
      }

      // border-width: 2px;
      border: unset;
    }

    .arrow {
      position: absolute;
      top: 32px;
      right: calc(381px - 12px);
      background-color: #1F1F1F;
      height: 24px;
      width: 24px;
      z-index: 1;
      transform: rotate(45deg);

      @media (max-width: 768px) {
        display: none;
      }
    }

    .button {
      position: absolute;
      height: 40px;
      width: 40px;
      border-radius: 20px;
      // background-color: #1F1F1F;
      // box-shadow: 0px 0px 7px rgba(0, 0, 0, 1);
      z-index: 1;
      top: 25px;
      right: calc(381px + 20px);
      transition: opacity 0.3s ease 0s;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      
      @media (max-width: 768px) {
        display: none;
      }

      // &:hover {
      //   box-shadow: 0px 0px 7px rgba(0, 0, 0, 1);
      // }

      > img {
        height: 21px;
      }
    }

    .video-wrapper {
      flex: 1;
      background-color: #000;
      width: calc(100% - 381px);
      // display: flex;
      // justify-content: center;
      // align-items: center;

      > div {
        width: 100%;
        height: 100%;
      }

      @media (max-width: 768px) {
        flex: unset;
        width: calc(100vw - 33px);
        height: calc((100vw - 33px) * 0.5625);
        border: 2px solid #FF6600;
        border-radius: 24px;
        overflow: hidden;

        video {
          border-radius: 24px !important;
        }
      }

      // .video-aspect {
      //   background-color: #fff;
      //   aspect-ratio: 16/9;
      //   width: 100%;
      // }
    }

    .ingredients-wrapper {
      position: relative;
      width: 381px;
      display: flex;
      flex-direction: column;
      background-color: #1F1F1F;

      @media (max-width: 768px) {
        width: 100%;
        flex: 1;
        // max-height: 550px;
      }

      .header {
        height: 71px;
        display: flex;
        justify-content: center;
        align-items: flex-end;
        padding-bottom: 10px;

        @media (max-width: 768px) {
          height: 56px;
          padding-bottom: 6px;
        }

        img {
          height: 36px;

          @media (max-width: 768px) {
            height: 32px;
          }
        }
      }

      .separator {
        height: 2px;
        background-color: black;
        width: 80%;
        margin: 0px auto;
      }

      .content {
        flex: 1;
        // background-color: blue;
        padding: 12px 24px;
        padding-bottom: 32px;
        max-height: calc((100vw - 34px - 36px - 2px - 24px)* 0.5625);
        overflow-x: auto;

        @media (max-width: 768px) {
          padding: 12px 21px;
          max-height: calc(75vh - 48px - 2px - ((100vw - 33px) * 0.5625));

          @supports (max-height: calc(75svh - 48px - 2px - ((100vw - 33px) * 0.5625))) {
            max-height: calc(75svh - 48px - 2px - ((100vw - 33px) * 0.5625));
          }
        }
        
        p {
          white-space: pre-line;
          font-size: 15px;
          font-weight: 400;
        }
      }

      .layer {
        display: none;
        position: absolute;
        bottom: 0px;
        background: linear-gradient(360deg, #1F1F1F 0%, rgba(31, 31, 31, 0) 100%);
        height: 60px;
        width: 100%;

        @media (max-width: 768px) {
          display: block;
        }
      }

      // img {
      //   margin: 21px auto 12px auto;

      //   @media (max-width: 768px) {
      //     margin: 12px auto;
      //   }
      // }

      // .separator {
      //   height: 2px;
      //   background-color: #000;
      //   width: 80%;
      //   margin: 0px auto;
      //   margin-bottom: 12px;
      // }

      // .list {
      //   flex: 1;
      //   font-size: 15px;
      //   font-weight: 400;
      //   padding-left: 48px;
      //   padding-right: 6px;
      //   overflow: auto;
      //   padding-bottom: 32px;
      //   list-style: disc;
      //   list-style-position: outside;

      //   @media (max-width: 768px) {
      //     padding-left: 38px;
      //     padding-right: 12px;
      //     padding-bottom: 12px;
      //   }
      // }
    }
  }
}

.profile-quizzes {
  margin-top: 4rem;

  .quizzes {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 0 12rem;

    @media (max-width: 950px) {
      padding: 0;
    }

    .quizz-logo-wrapper {
      display: flex;
      flex-direction: row;
      justify-content: center;

      .quizz-logo {
        height: 126px;
      }      
    }
    
    .quizz-line {
      display: flex;
      flex-direction: row;
      gap: 1rem;
      justify-content: space-between;

      @media (max-width: 950px) {
        justify-content: center;
        align-items: center;
        gap: 2rem;
      }

      .category-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 8px;
        min-width: 100px;

        @media (max-width: 950px) {
          align-items: flex-end;
          width: 150px;
        }

        .category-icon {
          height: 60px;
          width: 60px;

          @media (max-width: 950px) {
            display: none;
          }
        }

        .category-label {
          font-weight: 600;
          font-size: 20px;
          text-align: center;

          @media (max-width: 950px) {
            font-weight: 300;
          }
        }
      }

      .progress-wrapper {
        flex: 1;
        max-width: 700px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        @media (max-width: 950px) {
          display: none;
        }

        .progress-title {
          font-weight: 600;
          font-size: 20px;
        }

        .progress-info {
          flex: 1;
          display: flex;
          flex-direction: row;
          justify-content: center;
          width: 100%;
          align-items: center;
          gap: 24px;

          .progress-bar-wrapper {
            height: 5px;
            background-color: #ccc;
            border-radius: 3px;
            flex: 1;

            .progress-bar {
              height: 100%;
              border-radius: 3px;
            }
          }

          .progress-numbers-wrapper {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            gap: 4px;

            .progress-numbers {
              font-size: 20px;
            }

            .progress-percent {
              font-size: 14px;
            }
          }
        }
      }

      .total-points-wrapper {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 8px;
        padding-top: 8px;

        @media (max-width: 950px) {
          width: 150px;
        }

        .total-points-wrapper {
          font-weight: 600;
          font-size: 20px;

          @media (max-width: 950px) {
            display: none;
          }
        }

        .total-points {
          font-weight: 600;
          font-size: 20px;
        }

        @media (max-width: 950px) {
          padding-top: 0px;
        }
      }

      &.fb {
        .category-wrapper .category-label {
          color: #ff6600;
          
          @media (max-width: 950px) {
            color: #fff;
          }
        }

        .progress-wrapper {
          .progress-info .progress-bar {
            background-color: #ff6600;
          }
          .progress-numbers-wrapper .progress-numbers {
            color: #ff6600;
          }
        }

        .total-points-wrapper .total-points {
          color: #ff6600;
        }
      }

      &.ops {
        .category-wrapper .category-label {
          color: #76bd87;
          
          @media (max-width: 950px) {
            color: #fff;
          }
        }

        .progress-wrapper {
          .progress-info .progress-bar {
            background-color: #76bd87;
          }
          .progress-numbers-wrapper .progress-numbers {
            color: #76bd87;
          }
        }

        .total-points-wrapper .total-points {
          color: #76bd87;
        }
      }

      &.sm {
        .category-wrapper .category-label {
          color: #ff7dd7;
          
          @media (max-width: 950px) {
            color: #fff;
          }
        }

        .progress-wrapper {
          .progress-info .progress-bar {
            background-color: #ff7dd7;
          }
          .progress-numbers-wrapper .progress-numbers {
            color: #ff7dd7;
          }
        }

        .total-points-wrapper .total-points {
          color: #ff7dd7;
        }
      }
    }
  }
}

.profile-leaderboard-badges {
  display: flex;
  flex-direction: column;
  align-items: center;

  .title {
    font-weight: 700;
    font-size: 24px;
    margin-bottom: 32px;
    letter-spacing: 5px;
    margin-top: 64px;
  }

  .stat-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 650px;

    @media (max-width: 950px) {
      width: 100%;
      justify-content: space-around;
      flex-direction: column;
    }

    .stat-item {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 8px;

      .number-wrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 8px;
        font-weight: 600;
        font-size: 28px;

        .icon {
          font-size: 32px;
          font-weight: bold;
          margin-top: 1px;
        }

        &.expert {
          color: #53c1ff;
        }

        &.fastest {
          color: #ffea32;
        }

        &.star {
          color: #9a92ff;
        }
      }

      .label {
        font-size: 14px;
      }
    }
  }
}

.profile-activity {

  .title {
    font-weight: 700;
    font-size: 24px;
    margin-bottom: 16px;
    letter-spacing: 5px;
    margin-top: 64px;
  }

  .activity-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    @media (max-width: 768px) {
      flex-direction: column;
    }

    .activity-item {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      .value {
        font-weight: 700;
        font-size: 58px;
      }

      .label {
        font-weight: 600;
        font-size: 18px;
      }

      .average {
        margin-top: 8px;
        font-weight: 700;
        font-size: 14px;
        color: #FFEA32;
        text-align: left;
      }
    }
  }
}

.profile-quizz-list {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px 12rem;


  @media (max-width: 950px) {
    padding: 16px 0;
  }

  .quizz-item {
    position: relative;
    overflow: hidden;
    height: 70px;
    background-color: #1F1F1F;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 95px;
    padding-right: 24px;
    gap: 32px;

    &.failed {
      .banner {
        background-color: #FC434E;
        
        .icon {
          opacity: 0;
        }
      }

    }

    &.passed {
      .banner {
        background-color: #327228;        
      }

    }

    .banner {
      position: absolute;
      height: 27px;
      transform: rotate(-30deg);
      padding: 3px 45px 5px 45px;
      font-weight: 600;
      font-size: 14px;
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: -2px;
      left: -42px;
      top: 14px;

      .icon {
        height: 23px;
        width: 23px;
        transform: rotate(30deg);
      }
    }

    @media (max-width: 950px) {
      padding-left: 55px;
      padding-right: 12px;
      gap: 8px;

      .title {
        flex: 1;
      }

      .banner {
        left: -48px;
        top: 8px;
        font-size: 12px;

        .icon {
          height: 16px;
          width: 16px;
        }
      }
    }

    .title {
      font-weight: 500;
      font-size: 17px;
      color: #FF6600;
      text-align: left;
      text-transform: uppercase;
      min-width: 250px;

      @media (max-width: 950px) {
        min-width: 150px;
        font-size: 14px;
      }

      @media (max-width: 750px) {
        min-width: 100px;
        font-size: 12px;
      }
    }

    .score-wrapper {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 4px;
      color: #D2D2D2;

      .score {
        font-size: 14px;
      }

      .date {
        font-weight: 600;
        font-size: 12px;
      }
    }

    .to-quiz {
      background-color: #414141;
      color: #FFFFFF;
      height: 38px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0px 16px;
      border-radius: 6px;
      text-decoration: none;
      font-weight: 700;
      font-size: 10px;
      min-width: 77px;;

      &:hover {
        opacity: .75;
      }
    }
  }
}

.image-comments-button {
  position: absolute;
  right: 15px;
  top: 15px;
  height: 24px;
  display: flex;
  align-items: center;
  gap: 4px;
  z-index: 999;

  @media screen and (max-width: 1300px) {
    bottom: 15px;
    top: auto;
  }

  .loading {
    font-weight: 600;
    max-width: 100px;
    overflow: hidden;
  }

  .close-button {
    background-color: #fff;
    color: #000;
    padding: 3px 3px 4px 3px;
    border-radius: 10px;
    height: 14px;
    width: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    cursor: pointer;
    font-size: 11px;
  }

  .image-name {
    font-weight: 600;
    max-width: 160px;
    overflow: hidden;
    font-size: 11px;
  }

  .action-wrapper {
    cursor: pointer;
    height: 24px;
    width: 24px;
    position: relative;

    img {
      height: 100%;
      width: 100%;
      cursor: pointer;
    }

    input {
      position: absolute;
      height: 100%;
      width: 100%;
      z-index: 1;
      top: 0;
      opacity: 0;
      cursor: pointer;
    }
  }
}
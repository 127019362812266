.terms-conditions {
	// font-family: 'signikaregular';
	padding-top: 40px;
	text-align: justify;
	&__header {
		// font-family: 'montserratbold';
		font-size: 2.2rem;
		margin-bottom: 20px;
		@include for-phone-only {
			font-size: 2rem;
		}
	}
	&__sub-header {
		// font-family: 'montserratbold';
		font-size: 1.8rem;
		margin-bottom: 20px;
		@include for-phone-only {
			font-size: 1.8rem;
		}
	}
	&__paragraph {
		// text-indent: 4%;sssssss
	}
	@include for-phone-only {
		padding: 30px;
	}
}

.indent {
	text-indent: 4%;
}

.double-indent {
	text-indent: 8%;
}

// footer
.terms-footer {
	margin-top: 20px;

	@media (min-width: 1440px) {
		padding: 0 4%;
	}

	.small-medium-container {
		display: flex;
		flex-direction: column;

		@media (min-width: 1440px) {
			flex-direction: row;
		}
	}


	.terms-footer-divider {
		display: flex;
		flex-direction: column;
		padding-bottom: 47px;

		&.medium, &.small {
			@media (min-width: 1440px) {
				padding-bottom: 0;
			}
		}

		.header-wrap {
			display: flex;
			align-items: center;
			justify-content: center;

			@media (min-width: 1440px) {
				margin-bottom: 10px;
			}

			.line {
				border-bottom: 1px solid #B6B6B6;
				flex-grow: 0.4;
				height: 1px;

				@media (min-width: 1440px) {
					flex-grow: 0.9;
				}
			}

			.text {
				color: #B6B6B6;
				margin: 0 10px;
			}
		}

		ul {
			display: inline-flex;
			list-style: none;
			flex-wrap: wrap;
			margin: 0;
			padding: 0 30px;

			@include small-phone {
				padding: 0;
			}

			@media (min-width: 1440px) {
				padding: 0;
			}
			
			li {
				display: flex;
				margin: 0 auto;
				cursor: pointer !important;
				a {
					margin: 0px auto;
				}
				span {
					margin: auto;
					display: block;
					background-repeat: no-repeat;
					background-size: contain;
					background-position: center;
					filter: grayscale(1);
					min-height: 100px;
					min-width: 130px;

					@media (min-width: 1440px) {
						min-height: auto;
						height: 100px;
					}

					&:hover {
						filter: none;
						transition: filter 0.3s;
					}
				}
			}
		}

		&.large {

			li {
				flex-basis: 20%;
				max-width: 20%;

				@media (min-width: 1440px) {
					flex-basis: 8%;
					max-width: 10%;
				}
				
				@include for-phone-only {
					flex-basis: 50%;
    				max-width: 50%;
				}

					&:nth-of-type(1) span {
						background-image: url('/assets/images/parkhyatt.png');
						min-width: 160px;
						
						@media (min-width: 1440px) {
							min-width: 150px;
						}
					}

					&:nth-of-type(2) {
						
						span {
							background-image: url('/assets/images/miraval.png');
	
							@media (min-width: 1440px) {
								min-width: 121px;
							}
						}
					}
					

					&:nth-of-type(3) span {
						background-image: url('/assets/images/hyatt-grand.png');
					}

					&:nth-of-type(4) {

						span {
							background-image: url('/assets/images/hyatt-regency-logo.png');

							@media (min-width: 1440px) {
								min-width: 173px;
							}
						}
					} 

					&:nth-of-type(5) {

						span {
							background-image: url('/assets/images/hyatt.png');

							@media (min-width: 1440px) {
								min-width: 164px;
							}
						}
					}
					

					&:nth-of-type(6) {
						@media (min-width: 1440px) {
							flex-basis: 5%;
							max-width: 11%;
						}
						span {
							background-image: url('/assets/images/hyatt-zilara.png');

							 @media (min-width: 1440px) {
								min-width: 154px;
							 }
						}
					}
					

					&:nth-of-type(7) {
						@media (min-width: 1440px) {
							max-width: 6%;
						}

						span {
							background-image: url('/assets/images/hyatt-place2.png');

							@media (min-width: 1440px) {
								min-width: 76px;
								height: 80px;
							}
						}
					}
					

					&:nth-of-type(8) {
						@media (min-width: 1440px) {
							max-width: 6%;
						}

						span {
							background-image: url('/assets/images/hyatt-house.png');

							@media (min-width: 1440px) {
								min-width: 76px;
								height: 80px;
							}
						}
					}
					

					&:nth-of-type(9) {

						span {
							background-image: url('/assets/images/ur-cove3.webp');

							@media (min-width: 1440px) {
								min-width: 110px;
								height: 80px;
							}
						}
					}
					

					&:nth-of-type(10) span {
						background-image: url('/assets/images/hyatt-residence-club.png');
					}
			}
		}

		&.medium {

			@media (min-width: 1440px) {
				width: 65%;
				margin-right: 5%;
			}

			li {
				@media (min-width: 1440px) {
					flex-basis: 20%;
					max-width: 20%;
				}

				&:nth-of-type(1) span {
					background-image: url('/assets/images/andaz.png');
				}

				&:nth-of-type(2) {
					@media (min-width: 1440px) { 
						max-width: 18%;
					}

					span {
						background-image: url('/assets/images/alila.png');
						min-width: 90px;
					}
				}
				
				&:nth-of-type(3) {
					span {
						background-image: url('/assets/images/thompson-hyatt.png');
						min-width: 180px;
					}
				}
				

				&:nth-of-type(4) span {
					background-image: url('/assets/images/hyatt-centric-vector-logo.png');
				}

				&:nth-of-type(5) span {
					background-image: url('/assets/images/exhale.png');
				}
			}
		}

		&.small {
			@media(min-width: 1440px) {
				width: 30%;
			}

			li {
				&:nth-of-type(1) span {
					background-image: url('/assets/images/ub.png');
				}

				&:nth-of-type(2) span {
					background-image: url('/assets/images/destination.png');
				}

				&:nth-of-type(3) span {
					background-image: url('/assets/images/joie-de-vivre.jpg');
				}
			}
		}
	}
}
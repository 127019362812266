section.classics {
    .classics-logo {
        margin: 32px auto 0px auto;
        max-width: 435px;
        width: 100%;
    }

    .classics-title {
        font-size: 58px;
        color: #FF6600;
        font-weight: 700;
        text-transform: uppercase;
        text-align: center;
        margin: 0px;
        
        @media (max-width: 975px) {
            font-size: 28px;
            padding: 8px 0px;
        }
    }

    .list {
        .empty {
            margin: 0;
            display: flex;
            flex-direction: row;
            justify-content: center;

            > span {
                margin: 0;
                margin-top: 8px;
                font-size: 12px;
            }
        }

        .loader-wrapper {
            display: flex;
            justify-content: center;
            margin: 8px 0px;
            font-size: 18px;
            font-weight: bold;
        }

        .category {
            height: 56px;
            border-bottom: 4px solid #FF6600;
            margin-bottom: 8px;
            display: flex;
            align-items: center;
            padding: 0px 16px;
            margin-top: 16px;

            .name {
                font-size: 20px;
                font-weight: 600;
                text-transform: uppercase;
                flex: 1;
            }

            .count {
                font-size: 17px;
                font-weight: 500;
            }
        }
        
        .load-wrapper {
            display: flex;
            flex-direction: row;
            justify-content: center;
            padding-top: 8px;
        }
    }

    .order-wrapper {
        padding: 16px 4px;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;

        .order {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 8px;

        .label {
            font-weight: 600;
            font-size: 11px;
            color: rgba(255, 255, 255, 0.5);

            .icon-sorting {
            font-size: 13px;
            }
        }

        .value {
            font-weight: 500;
            font-size: 17px;
            position: relative;
            cursor: pointer;
            min-width: 65px;

            .overlay {
                display: none;

                &.open {
                    display: block;
                }
            }

            .popup {
                position: absolute;
                min-width: 115px;
                bottom: -75px;
                right: 0;
                display: none;
                border-radius: 8px;
                background-color: rgba(65, 65, 65, 1);
                flex-direction: column;
                gap: 1px;
                overflow: hidden;

            &.open {
                display: flex;
            }

            div {
                display: flex;
                align-items: center;
                gap: 4px;
                background-color: rgba(29, 29, 29, 1);
                height: 35px;
                padding: 0px 12px;
                cursor: pointer;

                &:hover {
                    background-color: rgba(65, 65, 65, 1);
                }

                .icon-check {
                    display: none;
                    margin-top: 2px;
                    font-size: 11px;
                    color: #fff;
                }

                &.active .icon-check {
                    display: block;
                }

                > span:last-child {
                    color: #fff;
                    font-weight: 400;
                    font-size: 14px;
                    margin: 0;
                }
            }
            }
        }
        }
    }

    .recipe {
        cursor: pointer;
        text-decoration: none;
        border-bottom: 1px solid rgba(29, 29, 29, 1);
        transition: all 0.3s ease;
        display: flex;
        flex-direction: column;

        .mobile-released {
            color: #d2d2d2;
            display: none;
            font-size: 11px;
            font-weight: 300;
            padding-bottom: 4px;
            margin-left: 32px;

            @media (max-width: 975px) {
                display: block;
            }
        }

        &:hover {
            background-color: rgba(29, 29, 29, 1);

            .mobile-released {
                color: #d2d2d2;
            }

            .wrapper-info {
                .name > span:nth-child(2),
                .time > span:nth-child(2) {
                    color: #d2d2d2;
                }            
            }
        }

        &.watched {
            .wrapper-info {
                .name {
                    > span:nth-child(1) {
                        display: block;
                        font-size: 14px;
                    }

                    > span:nth-child(2) {
                        color: rgba(113, 113, 113, 1);
                    }
                }            
            }
        }

        .wrapper-info {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            // height: 48px;
            gap: 8px;
            padding: 7px 32px 0px 32px;

            @media (min-width: 975px) {
                padding-bottom: 7px;
            }

            .name {
                flex: 1;
                display: flex;
                flex-direction: row;
                // justify-content: space-between;
                align-items: center;
                gap: 12px;

                > span:nth-child(1) {
                    color: #FF6600;
                    margin-left: 4px;
                    display: none;
                }

                > span:nth-child(2) {
                    font-weight: 600;
                    font-size: 17px;
                    color: #d2d2d2;
                    text-transform: uppercase;

                    @media (max-width: 975px) {
                        font-size: 12px;
                    }
                }
            }

            .time {
                > span:nth-child(1) {
                    font-weight: 500;
                    font-size: 17px;
                    color: rgba(210, 210, 210, 1);
                }

                > span:nth-child(2) {
                    font-weight: 300;
                    font-size: 14px;
                    margin-left: 8px;
                    color: #d2d2d2;

                    @media (max-width: 975px) {
                        display: none;
                    }
                }
            }
        }
    }
}
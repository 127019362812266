.not-available-page {
	position: relative;
	text-align: center;
}

.not-available {
	&__text {
		position: absolute;
		top: 40%;
		left: 50%;
		transform: translateX(-50%);
		font-family: 'montserratbold';
		font-size: 3.2rem;
		color: $white;
	}
	&__bg-image {
		margin-top: 40px;
		width: 100%;
		height: 100%;
	}
}
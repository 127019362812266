// overrride react player/wistia sizing

.player-gradient {
  height: 100%;
  width: 100%;
  z-index: 2;
  background: rgba(0, 0, 0, 0);
  background: -moz-linear-gradient(
    top,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0) 39%,
    rgba(0, 0, 0, 1) 98%,
    rgba(0, 0, 0, 1) 100%
  );
  background: -webkit-gradient(
    left top,
    left bottom,
    color-stop(0%, rgba(0, 0, 0, 0)),
    color-stop(39%, rgba(0, 0, 0, 0)),
    color-stop(98%, rgba(0, 0, 0, 1)),
    color-stop(100%, rgba(0, 0, 0, 1))
  );
  background: -webkit-linear-gradient(
    top,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0) 39%,
    rgba(0, 0, 0, 1) 98%,
    rgba(0, 0, 0, 1) 100%
  );
  background: -o-linear-gradient(
    top,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0) 39%,
    rgba(0, 0, 0, 1) 98%,
    rgba(0, 0, 0, 1) 100%
  );
  background: -ms-linear-gradient(
    top,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0) 39%,
    rgba(0, 0, 0, 1) 98%,
    rgba(0, 0, 0, 1) 100%
  );
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0) 39%,
    rgba(0, 0, 0, 1) 98%,
    rgba(0, 0, 0, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#000000', GradientType=0 );
}
.like-reply {
  margin-bottom: 5px;
}

.comment-item-reply {
  .like-reply {
    margin-bottom: 0px;
  }
}

.reply-span {
  font-weight: 700;
  color: white;
  text-decoration: underline;
  cursor: pointer;
  transition: color 0.15s ease-in-out;
  margin-left: 5px;
  &:hover {
    color: #d2d2d2;
  }
}

.comment-save,
.comment-edit,
.comment-delete {
  background: #1f1f1f;
  border-radius: 2px;
  padding: 3px 12px;
  display: inline-block;
  font-size: 12px;
  margin-left: 12px;
  cursor: pointer;

  .comment-item-reply & {
    border: 1px solid #414141;
  }
}

.comment-date {
  color: #d2d2d2;
  font-size: 10px;
}

.replies-show-more {
  padding-left: 40px;
  margin-bottom: 8px;
  font-size: 14px;
}
.like-reply {
  .like-count {
    .icon-like {
      margin-right: 2px !important;
    }

    cursor: pointer;
    &.active {
      color: #2672ec;
    }

    &:hover {
      color: #2672ec;
    }
  }
}

.speakers-list {
  @media (min-width: 768px) {
    display: flex;
    flex-wrap: wrap;
  }
}

.speaker-infos {
  .speaker-name {
    font-size: 20px;
    margin-bottom: 4px;
  }
}

.speaker-item {
  .inner {
    min-height: 100px;
    .medias {
      span {
        background-size: cover;
        background-position: center;
      }
    }
  }
}

footer {
  .suggestions {
    p {
      span {
        font-weight: 700;
        text-decoration: underline;
        cursor: pointer;
        &:hover {
          color: #d2d2d2;
        }
      }
    }
  }
}

.speaker-header {
  .job-title,
  .infos {
    font-size: 24px;
  }
}

.hde {
  .player-wrapper,
  .video-player {
    position: relative;
    width: 100%;
    &:before {
      content: "";
      display: block;
      padding-top: 56.25%;
    }
    .video-home,
    .video-wrapper {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: 0;
      width: 100% !important;
      height: 100% !important;
      .play {
        span {
          display: none;
        }
      }
    }
    .video-home {
      width: 100% !important;
    }
  }
}

.video-page {
  .viewport {
    padding-top: 0;

    .inner {
      max-width: 1440px;
    }

    @media (min-width: 1024px) {
      padding-left: 64px;
      padding-right: 64px;
    }
  }

  .video-wrapper {
    position: relative;
    width: 100%;
    &:before {
      content: "";
      display: block;
      padding-top: 56.25%;
    }

    .video-home,
    .video-wrapper {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: 0;
      width: 100% !important;
      height: 100% !important;
      .play {
        span {
          display: none;
        }
      }
    }

    @media (min-width: 768px) {
      margin: 0;
    }

    .play,
    .medias {
      opacity: 1;
      transition: opacity 0.15s ease-in-out;
    }

    &.playing {
      .play,
      .medias {
        opacity: 0;
      }
    }

    .play {
      position: absolute;
      z-index: 2;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;

      span {
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }

      .icon-playbuttonsmall,
      .icon-playbutton {
        &:after {
          content: "";
          z-index: 0;
          position: absolute;
          background-color: rgba(0, 0, 0, 0.8);
          border-radius: 100%;
        }

        &:before {
          position: relative;
          z-index: 1;
        }
      }

      .icon-playbuttonsmall {
        display: block;

        &:after {
          top: 0.3125vw;
          left: 0.3125vw;
          right: 0.3125vw;
          bottom: 0.3125vw;
        }

        &:before {
          font-size: 10vw;
        }

        @media (min-width: 768px) {
          display: none;
        }
      }

      .icon-playbutton {
        display: none;

        &:after {
          top: 2px;
          left: 2px;
          right: 2px;
          bottom: 2px;
        }

        &:before {
          font-size: 110px;
        }

        @media (min-width: 768px) {
          display: block;
        }
      }
    }

    .medias {
      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;

      span {
        display: block;
        height: 100%;
        background-size: cover;
        background-position: center;
      }
    }

    video {
      position: relative;
      z-index: 0;
    }
  }

  .action-infos {
    padding: 7px 0;
    border-bottom: 1px solid #353535;

    @media (min-width: 768px) {
      padding: 16px 0;
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;
    }

    .right {
      font-size: 12px;
      font-family: "roboto";
      font-weight: 500;
      line-height: 100%;
      // border-bottom: 1px solid #1C1C1C;

      .views {
        .view-count {
          margin-right: 16px;
        }

        .like-count {
          cursor: pointer;
          transition: color 0.15s ease-in-out;

          span {
            margin-right: 6px;
            display: inline-block;
          }

          .icon-like {
            font-size: 14px;
            position: relative;
            top: 1px;
            margin-right: 2px;
          }

          &.active {
            color: #2672ec;
          }
        }
      }

      @media (min-width: 768px) {
        border-bottom: none;
        font-size: 20px;

        .views {
          .like-count {
            .icon-like {
              font-size: 20px;
              position: relative;
              top: 1px;
              margin-right: 2px;
            }

            &:hover {
              color: #2672ec;
            }
          }
        }
      }
    }
  }

  .video-infos {
    .right {
      font-size: 12px;
      font-family: "roboto";
      font-weight: 700;
      padding: 5px 0;
      margin-bottom: 10px;
      border-bottom: 1px solid #1c1c1c;

      .views {
        .view-count {
          margin-right: 16px;
        }

        .like-count {
          cursor: pointer;
          transition: color 0.15s ease-in-out;

          span {
            margin-right: 6px;
            display: inline-block;
          }

          .icon-like {
            font-size: 14px;
            position: relative;
            top: 1px;
            margin-right: 2px;
          }

          &.active {
            color: #2672ec;
          }
        }
      }

      @media (min-width: 768px) {
        border-bottom: none;
        font-size: 20px;
        padding: 20px 0;

        .views {
          .like-count {
            .icon-like {
              font-size: 20px;
              position: relative;
              top: 1px;
              margin-right: 2px;
            }

            &:hover {
              color: #2672ec;
            }
          }
        }
      }
    }

    .left {
      padding: 10px 0 0;
      .video-title {
        font-weight: 700;
        font-size: 12px;
        margin-bottom: 4px;
      }

      .video-category {
        font-size: 11px;
        margin-bottom: 4px;
        .cat {
          margin-right: 8px;
        }
      }

      .video-tags {
        margin-bottom: 4px;
        .tag {
          display: inline-block;
          font-size: 8px;
          border: 1px solid #ffffff;
          box-sizing: border-box;
          border-radius: 5px;
          padding: 2px 8px;
          margin-right: 10px;
          cursor: pointer;
        }
      }

      .speaker {
        font-size: 11px;
        font-weight: 300;
        margin-bottom: 4px;
        a {
          color: white;
          text-decoration: underline;
          font-weight: 500;

          &:hover {
            color: #d2d2d2;
          }
        }
      }

      .realease-date {
        font-size: 10px;
        font-weight: 300;
      }

      @media (min-width: 768px) {
        padding: 20px 0;
        .video-title {
          font-size: 24px;
          margin-bottom: 10px;
        }
        .video-category {
          font-size: 18px;
          margin-bottom: 10px;
        }

        .video-tags {
          margin-bottom: 8px;
          .tag {
            display: inline-block;
            font-size: 12px;
            border-radius: 5px;
            padding: 2px 8px;
          }
        }

        .speaker {
          font-size: 14px;
          margin-bottom: 8px;
        }

        .realease-date {
          font-size: 14px;
        }
      }
    }
  }

  .bottom {
    @media (min-width: 768px) {
      .main-video {
        width: 100%;
        margin-right: -340px;
        padding-right: 355px;
        float: left;
        position: relative;
        z-index: 0;
      }
      .other-videos {
        width: 340px;
        float: right;
        position: relative;
        z-index: 1;
      }
    }
    @media (min-width: 1024px) {
      .main-video {
        width: 100%;
        margin-right: -440px;
        padding-right: 460px;
        float: left;
      }
      .other-videos {
        width: 440px;
        float: right;
      }
    }
  }

  .other-videos {
    .next-videos,
    .related-videos {
      background: #1f1f1f;
      padding: 16px 15px;
      margin-bottom: 12px;

      h2.title {
        @media (min-width: 1024px) {
          padding-left: 17px;
        }
      }
    }
  }

  .other-videos {
    @media (max-width: 768px) {
      margin: 0 -15px;
    }

    .video-wrapper {
      position: relative;
  
      &:before {
        content: "";
        display: block;
        padding-top: 56.25%;
      }
  
      .play {
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        height: 10vw;
        width: 10vw;
        z-index: 1;
  
        .icon-playbuttonsmall {
          display: block;
  
          &:after {
            content: "";
            display: block;
            position: absolute;
            border-radius: 100%;
            top: 0.3125vw;
            left: 0.3125vw;
            right: 0.3125vw;
            bottom: 0.3125vw;
            background-color: rgba(0, 0, 0, 0.8);
            z-index: 0;
          }
  
          &:before {
            font-size: 10vw;
            display: block;
            position: relative;
            z-index: 1;
          }
        }
  
        @media (min-width: 768px) {
          height: 32px;
          width: 32px;
  
          .icon-playbuttonsmall {
            &:after {
              top: 1px;
              left: 1px;
              right: 1px;
              bottom: 1px;
            }
  
            &:before {
              font-size: 32px;
            }
          }
        }
      }
  
      .medias {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 0;
  
        span {
          display: block;
          height: 100%;
          width: 100%;
          background-size: cover;
          background-position: center;
        }
      }
    }
  }

  .o-video,
  .r-video {
    margin: 0;
    overflow: visible;
    width: 70%;

    @media (min-width: 768px) {
      width: auto;
      margin: 0 -15px;

      .swiper-wrapper {
        transform: none !important;
        flex-direction: column;
      }
    }
  }

  .swiper-slide {
    padding: 6px 10px;
    transition: all 0.15s ease-in-out;

    &.ope a {
      color: #76bd87 !important;
    }
    &.fnb a {
      color: #ff6600 !important;
    }
    &.snm a {
      color: #ff7dd7 !important;
    }

    &.swiper-slide-active {
      padding: 6px;
      background: #363636;
    }

    .video-infos {
      padding-top: 6px;
      min-height: 56px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .video-title {
        font-size: 10px;
        font-weight: 600;
        display: flex;
        align-items: baseline;

        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;

        .icon-playbutton-noborder {
          width: 16px;
          height: 16px;
          &.ope {
            color: #76bd87;
          }
          &.fnb {
            color: #ff6600;
          }
          &.snm {
            color: #ff7dd7;
          }         
        }
      }
      .speaker {
        font-size: 10px;
        font-family: "roboto";
      }
      .video-category {
        font-size: 8px;
      }
      .video-detail {
        display: flex;
        justify-content: space-between;

        .time {
          font-size: 14px;
        }
        .viewed {
          font-size: 12px;
        }

        .left-info {
          float: right;
  
          span {
            margin-right: 0;
            margin-left: 6px;
  
            @media (min-width: 1024px) {
              margin-left: 15px;
            }
  
            span {
              margin-left: 0;
              margin-right: 4px;
            }
          }
  
          .like-count.active {
            color: #2672ec;
          }
  
          .viewed {
            span {
              color: #76bd87;
              margin-right: 4px;
            }
            &.fnb span {
              color: #ff6600;
            }
            &.snm span {
              color: #ff7dd7;
            }

            .viewd-text {
              color: white !important;
            }
          }
        }
      }
    }

    @media (min-width: 768px) {
      &.swiper-slide-active {
        background: transparent;
      }

      .video-wrapper {
        width: 100px;
        float: left;
      }

      .video-infos {
        padding: 0 10px;
        padding-right: 0;
        padding-top: 0;
        float: right;
        width: 100%;
        margin-left: -100px;
        padding-left: 120px;

        .video-title {
          font-size: 14px;
        }
        .speaker {
          font-size: 10px;
        }
        .video-category {
          display: flex;
          justify-content: space-between;
          font-size: 12px;
        }
      }
    }

    @media (min-width: 1024px) {
      padding: 16px 32px;
      margin-right: 0 !important;
      position: relative;

      &.swiper-slide-active {
        background: transparent;
        padding: 16px 32px;
      }

      .video-wrapper {
        width: 100px;
        float: left;
      }

      .video-infos {
        padding: 0 10px;
        padding-right: 0;
        padding-top: 0;
        float: right;
        width: 100%;
        margin-left: -100px;
        padding-left: 120px;
      }

      &:before {
        content: "";
        display: block;
        border-bottom: 1px solid #343434;
        position: absolute;
        left: 32px;
        right: 32px;
        bottom: 0;
      }

      &:last-child:before {
        display: none;
      }
    }
  }

  .swiper-button-next,
  .swiper-button-prev {
    top: -30%;
    bottom: -30%;
    height: auto;
    &:after {
      font-family: "icomoon";
      color: white;
      font-weight: normal;
      z-index: 1;
    }

    &:before {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      width: calc(50vw - 25px);
      pointer-events: none;
    }

    @media (min-width: 768px) {
      display: none;
    }
  }

  .swiper-button-prev {
    left: 0;
    display: none;
    &:after {
      content: "\e913";
    }
  }

  .swiper-button-next {
    right: auto;
    left: 105%;
    width: calc(30vw + 2px);

    &:after {
      content: "\e914";
    }

    &:before {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0px;
      background: rgba(0, 0, 0, 0);
      background: -moz-linear-gradient(
        left,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) 7%,
        rgba(0, 0, 0, 1) 95%,
        rgba(0, 0, 0, 1) 100%
      );
      background: -webkit-gradient(
        left top,
        right top,
        color-stop(0%, rgba(0, 0, 0, 0)),
        color-stop(7%, rgba(0, 0, 0, 0)),
        color-stop(95%, rgba(0, 0, 0, 1)),
        color-stop(100%, rgba(0, 0, 0, 1))
      );
      background: -webkit-linear-gradient(
        left,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) 7%,
        rgba(0, 0, 0, 1) 95%,
        rgba(0, 0, 0, 1) 100%
      );
      background: -o-linear-gradient(
        left,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) 7%,
        rgba(0, 0, 0, 1) 95%,
        rgba(0, 0, 0, 1) 100%
      );
      background: -ms-linear-gradient(
        left,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) 7%,
        rgba(0, 0, 0, 1) 95%,
        rgba(0, 0, 0, 1) 100%
      );
      background: linear-gradient(
        to right,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) 7%,
        rgba(0, 0, 0, 1) 95%,
        rgba(0, 0, 0, 1) 100%
      );
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#000000', GradientType=1 );
    }
  }

  .swiper-button-disabled {
  }
  .other-videos {
    overflow: hidden;
  }

}

.select {
  font-size: 14px;
}

.vid-count {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  line-height: 28px;
  color: #959595;

  @media (min-width: 768px) {
    font-size: 24px !important;
    margin-bottom: 22px;
  }
}

.cover {
  position: absolute;
  z-index: 5;
  top: 0;
  right: 0;
  bottom: 35px;
  left: 0;
  margin: 0 auto;
  background-size: 100% 100%;
  cursor: pointer;

  .watch-later {
    &:before {
      content: "";
    }
    display: none;

    position: absolute;
    cursor: pointer;
    z-index: 5;
    top: 16px;
    right: 38px;
    background-color: rgba(29, 29, 29, 0.8);
    padding: 4px 6px;
    border-radius: 4px;
    font-size: 12px;

    i {
      margin-right: 4px;
      position: relative;
      top: 1px;
      font-size: 14px;
    }
  }

  &:hover {
    .watch-later {
      display: block;
    }
  }
}

.video-item {
  .player-wrapper,
  .video-player {
    .video-home,
    .video-wrapper {
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      width: 100%;
      height: auto;
      position: relative;
      &:before {
        content: "";
        // position: absolute;
        padding-top: 56.25%;
        display: block;
      }
      iframe {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
      }
    }
  }

  .hide {
    opacity: 0;
  }
}

.quiz-item {
  width: 100%;
  background: #1F1F1F;
  border-radius: 12px;

  margin-bottom: 24px;

  .inner {
    display: flex;
    flex-direction: column;
    max-width: unset !important;

    padding: 19px 12px 26px 12px;

    &:after {
      display: none !important;
    }

    @media (min-width: 768px) {
      flex-direction: row;
      justify-content: flex-start;
      gap: 24px;
      padding: 18px 24px 24px 24px;
    }
  }

  .player-wrapper {
    position: relative;
    width: 100%;
    overflow: hidden;

    @media (min-width: 900px) {
      max-width: 292px;
    }
    .quiz-wrapper {
      position: relative;
      width: 100%;
      height: auto;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;

      border-radius: 12px;
      &:before {
        content: "";
        // position: absolute;
        padding-top: 56.25%;
        display: block;
      }
    }

    .quiz-passed,
    .quiz-failed {
      position: absolute;
      min-width: 148px;
      transform: rotate(-30deg);

      display: flex;
      align-items: center;

      font-weight: 600;
      font-size: 14px;
      line-height: 19px;
      text-align: center;

      color: #FFFFFF;

      padding: 4px 40px;
    }

    .quiz-passed {
      top: 9px;
      left: -37px;
      background: #327228;

      .icon {
        transform: rotate(30deg);
      }
    }

    .quiz-failed {
      top: 5px;
      left: -20px;
      background: #FC434E;
    }
  }

  .infos {
    margin-top: 7px;
    @media (min-width: 900px) {
      width: calc(100% - 292px - 24px);
      margin-top: 0;
    }

    .cat {
      font-size: 12px;
      line-height: 15px;
    }

    .quiz-title {
      font-weight: 700;
      font-size: 16px;
      line-height: 20px;

      color: #FFFFFF;

      margin-top: 4px;
    }

    .quiz-description {
      font-weight: 400;
      font-size: 14px;
      line-height: 23px;

      color: #FFFFFF;

      margin-top: 4px;
    }

    .quiz-buttons {
      display: flex;
      justify-content: space-between;

      margin-top: 18px;

      .quiz-button {
        border-radius: 6px;

        font-weight: 700;

        text-transform: uppercase;
        text-align: center;
        text-decoration: none;

        color: #FFFFFF;

        padding-top: 10px;
        padding-bottom: 10px;

        &.series {
          display: flex;
          align-items: center;
          background: #414141;

          font-size: 10px;
          line-height: 12px;

          padding: 10px 12px;

          .icon-playbuttonbold {
            font-size: 16px;
            margin-right: 12px;
          }
        }

        &.link {
          width: 127px;
          background: #2672EC;

          font-size: 14px;
          line-height: 16px;
        }
      }
    }
  }
}

.list-wrapper {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: auto;
  position: relative;
  &:before {
    content: "";
    // position: absolute;
    padding-top: 56.25%;
    display: block;
  }
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
  }
}

.hde-button {
  height: 39px;
  border-radius: 6px;
  padding: 10px 24px 10px 24px;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 700;
  text-decoration: none;
  background-color: transparent;
  border: 1px solid #d2d2d2;
  color: #d2d2d2;
  cursor: pointer;
  &:hover {
    border: 1px solid lighten(#d2d2d2, 5%);
    color: lighten(#d2d2d2, 5%);
  }

  &:active {
    border: 1px solid lighten(#d2d2d2, 10%);
    color: lighten(#d2d2d2, 10%);
  }
}

.button-wrapper {
  padding-top: 10px;
}

.form-err {
  margin-top: -10px;
  margin-bottom: -15px;
}

.thanks-content {
  text-align: center !important;
}

.quizzes-modal {
  max-height: 75vh;
  overflow-x: hidden;
  overflow-y: scroll;

  padding: 0 !important;


  &::-webkit-scrollbar {
    width: 6px;
  }
  
  &::-webkit-scrollbar-track {
    background-color:transparent;
    -webkit-border-radius: 2em;
    -moz-border-radius: 2em;
    border-radius:2em;
  }
  
  &::-webkit-scrollbar-thumb {
    background-color:#717171;
    -webkit-border-radius: 2em;
    -moz-border-radius: 2em;
    border-radius:2em;
  }

  .quizz-item {
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;
    padding: 14px 20px 12px 86px;

    @media (max-width: 768px) {
      padding: 16px 15px 10px 86px;
    }

    .quiz-passed,
    .quiz-failed {
      position: absolute;
      min-width: 148px;
      transform: rotate(-30deg);

      display: flex;
      align-items: center;

      font-weight: 600;
      font-size: 14px;
      line-height: 19px;
      text-align: center;

      color: #FFFFFF;

      padding: 4px 40px;
    }

    .quiz-passed {
      top: 9px;
      left: -45px;
      background: #327228;

      .icon {
        transform: rotate(30deg);
      }
    }

    .quiz-failed {
      top: 5px;
      left: -30px;
      background: #FC434E;
    }

    .info-wrap {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      h3 {
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 14px;

        letter-spacing: -0.02em;
        text-transform: uppercase;
        text-align: left;

        color: #76bd80;
        &.fb {
          color: #ff6600;
        }
        &.sm {
          color: #ff7dd7;
        }
      }

      .date {
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;

        color: #d2d2d2;
      }

      .score {
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 12px;

        color: #D2D2D2;
      }
    }

    a {
      background: #414141;
      border-radius: 6px;

      font-family: 'Roboto';
      font-style: normal;
      font-weight: 700;
      font-size: 10px;
      line-height: 12px;

      text-transform: uppercase;
      text-decoration: none;

      color: #FFFFFF;

      padding: 6px 12px;
    }
  }
  .separator {
    height: 1px;
    width: 100%;
    background-color: #353535;
  }
}

.not-found {
  margin: 100px auto;
  text-align: center;
  height: 100%;
  &__guy {
    margin: 0px auto;
    height: 520px;
  }
}

.hover-opacity {
  cursor: pointer;
  transition: all 0.1s ease-in-out;
  &:hover {
    opacity: 0.6;
  }
}

.slide-inner {
  .text {
    color: white;
  }
}

.loader {
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: black;
  z-index: 7;

  .logo-wrapper {
    position: absolute;
    left: 50%;
    top: 40%;
    transform: translate(-50%, -50%);
  }
}

.discover-style {
  text-decoration: underline;
  color: white;

  &:hover {
    color: #d2d2d2;
    text-decoration: underline;
  }
}

.upload-limit {
  margin-top: 14px;
  font-size: 12px;
  font-weight: 400;
  text-align: center;
}

.share-video {
  display: inline-block;
  padding: 6px 8px;
  margin-right: 12px;
  background: #1f1f1f;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  text-transform: uppercase;

  span {
    background: #414141;
    position: absolute;
    top: 100%;
    font-size: 12px;
    font-weight: 400;
    width: 320px;
    text-align: center;
    top: 100%;
    padding: 6px;
    left: 0;
    opacity: 0;
    transition: opacity 0.25s, top 0.25s;
    pointer-events: none;

    &:before {
      background: #414141;
      height: 12px;
      width: 12px;
      margin-left: -6px;
      content: "";
      display: block;
      position: absolute;
      left: 13%;
      top: -6px;
      transform: rotate(45deg);
    }

    @media (min-width: 768px) {
      left: 50%;
      margin-left: -160px;
      &:before {
        left: 50%;
      }
    }
  }

  span.visible {
    top: 130%;
    opacity: 1;
  }

  i {
    position: relative;
    top: 1px;
    margin-right: 4px;
  }

  @media (min-width: 1024px) {
    padding: 8px 10px;
    font-size: 16px;
  }
}

.delete-comment-confirm .actions {
  margin-top: 28px;
  div {
    display: inline-block;
    padding: 12px 24px;
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;
    margin: 0 8px;
    background: #414141;
    border-radius: 6px;
    cursor: pointer;

    &.delete {
      background-color: #2672ec;
    }
  }
}

.popup-wrapper.likes {
  .popup-inner {
    max-width: 360px !important;
  }

  .popup-content {
    padding: 0 22px;
    text-align: left !important;

    .item {
      padding: 12px 0;
      min-height: 61px;
      border-bottom: 1px solid #353535;

      .avatar {
        width: 34px;
        height: 34px;
        border-radius: 100%;
        background-position: center;
        background-size: cover;
        float: left;
        margin-right: 8px;
      }

      .right {
        .name {
          font-size: 12px;
          font-weight: bold;
          display: block;
        }
        .city {
          color: #d2d2d2;
          font-size: 12px;
        }
      }
    }
  }
}

@keyframes heartbeat {
  0% {
    transform: scale(0.85);
  }
  20% {
    transform: scale(1.1);
  }
  40% {
    transform: scale(0.85);
  }
  60% {
    transform: scale(1.1);
  }
  80% {
    transform: scale(0.85);
  }
  100% {
    transform: scale(0.85);
  }
}

.quizz {
  position: relative;
  background-color: #1f1f1f;

  .pulsing {
    animation: heartbeat 4.5s infinite;
  }

  .quizz-header {
    padding: 24px 0 24px;
    text-align: center;
    background-color: #0f0f0f;
    font-weight: 700;
    font-size: 20px;
    border-bottom: 4px solid #76bd80;
    position: relative;

    // .title {
    //   color: #76bd80;
    // }

    &.fb {
      border-color: #ff6600;

      // .title {
      //   color: #ff6600;
      // }
    }
    &.sm {
      border-color: #ff7dd7;

      // .title {
      //   color: #ff6600;
      // }
    }
  }
  .quizz-time {
    position: absolute;
    bottom: -32px;
    left: 50%;
    margin-left: -35px;

    img {
      margin: auto;
    }
  }
  .welcome {
    padding: 70px 0 20px;
    text-align: center;
    font-size: 16px;
    width: 65%;
    min-width: 280px;
    margin: auto;

    @media (min-width: 1024px) {
      font-size: 20px;
    }

    .note {
      font-size: 14px;
      color: #d2d2d2;
      margin-top: 40px;
    }

    .start {
      display: inline-block;
      padding: 10px 28px;
      background: #2672ec;
      border-radius: 6px;
      margin-top: 24px;
      cursor: pointer;
      font-size: 14px;
      text-transform: uppercase;
      font-weight: bold;
    }

    .actions {
      margin: 24px -2%;
      text-align: center;

      div {
        border-radius: 6px;
        padding: 10px;
        width: 139px;
        margin: 0 auto;
        display: block;
        font-weight: bold;
        font-size: 14px;
        text-transform: uppercase;
        cursor: pointer;
        @media (min-width: 1024px) {
          display: inline-block;
          margin: 0 6px;
          width: 156px;
        }
      }
      .prev {
        background: #171717;
        margin-bottom: 12px;
        @media (min-width: 1024px) {
          margin-bottom: 0;
        }
      }
      .next {
        background: #2672ec;
      }
      .disabled {
        background: #717171;
      }
    }
  }

  .quizz-results-header {
    padding: 20px 16px;
    margin-bottom: -34px;

    @media (min-width: 1024px) {
      padding: 28px 40px;
    }

    img {
      width: 33px;

      @media (min-width: 1024px) {
        width: 52px;
      }
    }

    span {
      float: right;
      margin-top: -32px;
      font-family: Roboto;
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      text-decoration-line: underline;

      @media (min-width: 1024px) {
        margin-top: -38px;
        font-size: 24px;
      }
    }
  }

  .questions {
    padding: 34px 16px;
    text-align: center;

    img {
      width: 100%;
    }

    .question-title {
      margin-top: 22px;
      font-weight: bold;
      font-size: 20px;
      font-weight: 500;
    }

    .question-hint {
      font-size: 14px;
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      color: #d2d2d2;
    }

    .question-answer {
      padding: 13px;
      color: #d2d2d2;
      border: 1px solid #717171;
      border-radius: 2px;
      margin-top: 16px;
      font-size: 12px;
      cursor: pointer;

      &.red {
        font-weight: bold;
        color: #fc434e;
        border: 1px solid #fc434e;
      }

      &.green {
        font-weight: bold;
        color: #76bd87;
        border: 1px solid #76bd87;
      }

      &.selected:before {
        content: "✓";
        display: inline-block;
        margin-right: 6px;
      }

      &.selected {
        &.ope {
          color: #76bd87;
          border-color: #76bd87;
        }
        &.fnb {
          color: #ff6600;
          border-color: #ff6600;
        }
        &.snm {
          color: #ff7dd7;
          border-color: #ff7dd7;
        } 
      }
    }

    .actions {
      margin: 24px -2%;
      text-align: center;

      div {
        border-radius: 6px;
        padding: 10px;
        width: 46%;
        margin: 0 2%;
        display: inline-block;
        font-weight: bold;
        font-size: 14px;
        text-transform: uppercase;
        cursor: pointer;
      }
      .prev {
        background: #171717;
      }
      .next {
        background: #2672ec;
      }
      .disabled {
        background: #717171;
      }
    }
  }

  @media (min-width: 1024px) {
    .questions > div {
      width: 50%;
      display: inline-block;
      padding: 20px;
      vertical-align: middle;

      .question-answer {
        font-size: 14px;
      }
    }
  }

  .quizz-results {
    padding: 70px 0 40px;
    text-align: center;

    &.fb {
      border-color: #ff6600;
    }
    &.sm {
      border-color: #ff7dd7;
    }

    .quizz-congrats {
      width: 180px;
      height: 150px;
      margin: auto auto 20px;
    }
    .quizz-score-title {
      font-size: 14px;
      @media (min-width: 1024px) {
        font-size: 16px;
      }
    }
    .quizz-score {
      font-weight: 600;
      font-size: 38px;
      color: #fc434e;
      @media (min-width: 1024px) {
        font-size: 44px;
      }
      &.green {
        color: #76bd87;
      }
    }

    .do-again {
      width: 220px;
      background: #2672ec;
      border-radius: 6px;
      padding: 10px;
      font-size: 14px;
      text-transform: uppercase;
      font-weight: 700;
      margin: 32px auto 0;
      cursor: pointer;
    }

    .quizz-pass {
      margin: auto;
      margin-bottom: 18px;
    }

    .actions {
      margin: 24px -2%;
      text-align: center;

      div {
        border-radius: 6px;
        padding: 10px;
        width: 156px;
        margin: 0 auto;
        display: block;
        font-weight: bold;
        font-size: 14px;
        text-transform: uppercase;
        cursor: pointer;
        @media (min-width: 1024px) {
          display: inline-block;
          margin: 0 6px;
        }
      }
      .prev {
        background: #171717;
        margin-bottom: 12px;
        @media (min-width: 1024px) {
          margin-bottom: 0;
        }
      }
      .next {
        background: #2672ec;
      }
      .disabled {
        background: #717171;
      }

      .helpme-passed-btn {
        background: #2672ec;
        margin-bottom: 12px;
        width: 145px;
        @media (min-width: 1024px) {
          margin-bottom: 0;
        }
      }
      .retake-btn {
        width: 119px;
        background: #414141;
      }
      .actions-btn-wrapper {
        display: inline-block;
      }
    }

    .helpme-passed-tip {
      font-size: 20px;
      font-weight: 600;
      width: 60%;
      margin: 24px auto 40px;
    }
  }

  .quizz-inprogress-header {
    text-align: center;
    background-color: #0f0f0f;
    font-weight: 700;
    font-size: 14px;
    position: relative;
    &.ope {
      border-color: #76bd87;
    }
    &.fnb {
      border-color: #ff6600;
    }
    &.snm {
      border-color: #ff7dd7;
    }

    @media (min-width:1024px) {
      font-size: 20px;
      border: none;
    }

    .progress-bar {
      display: flex;
      position: absolute;
      bottom: 0;
      height: 4px;
      width: 100%;

      .progress-item {
        background-color: #414141;

        &.ope {
          background-color: #76bd87;
        }
        &.fnb {
          background-color: #ff6600;
        }
        &.snm {
          background-color: #ff7dd7;
        }
      }
    } 
    
    .quiz-inprogress {
      display: flex;
      justify-content: space-between;
      
      .category {
        display: flex;
        align-items: center;
        width: 242px;
        justify-content: flex-start;
        padding: 12px 9px;

        @media (min-width:1024px) {
          padding: 24px 0;
          margin-left: 40px;
        }

  
        &.ope {
          color: #76bd87;
        }
        &.fnb {
          color: #ff6600;
        }
        &.snm {
          color: #ff7dd7;
        }
  
        .quiztime-pic {
          width: 49px;
          @media (min-width:1024px) {
            width: 78px;
          }
        }

        .category-text {
          font-size: 18px;
          padding-left: 12px;
          white-space: nowrap;
          @media (min-width:1024px) {
            padding-left: 20px;
          }
        }
      }

      .infos {
        display: flex;
        justify-content: flex-end;
        width: 100%;
        padding: 12px 21px 12px 7px;
        align-items: center;
        color: #D2D2D2;

        .title {
          padding-right: 8px;
        }

        @media (min-width:1024px) {
          padding: 24px;
          justify-content: space-between;
        }
      }
    }
  }

  .quizz-content {
    .help-container {
      padding: 70px 0 20px;
      text-align: center;
      font-size: 16px;
      width: 85%;
      min-width: 280px;
      margin: auto;

      .help-header {
        width: 65%;
        margin: auto;
        .title {
          font-weight: 600;
          font-size: 36px;
          display: block;
        }
        .description {
          margin-top: 8px;
          font-weight: 600;
          font-size: 20px;
          &span {
            display: block;
          }
        }
      }

      .help-questions-container {
        margin-top: 40px;

        .help-questions-item {
          display: flex;
          justify-content: space-between;
          border-bottom: 1px solid #717171;
          padding-bottom: 24px;
          margin-top: 40px;
          flex-direction: column;

          @media (min-width: 1024px) {
            flex-direction: row;
          }

          .question-container {
            display: flex;
            justify-content: flex-start;
            width: 100%;

            @media (min-width: 1024px) {
              width: 50%;
            }
            .question {
              font-size: 20px;
              padding-right: 40px;
              text-align: left;
  
              .question-desc {
                padding-left: 12px;
                font-weight: 600;
              }
            }
  
            .order {
              .order-desc {
                font-weight: 700;
                font-size: 20px;
                color: #FF574F;
              }
            }
          }
          

          .answers-container {
            display: flex;
            flex-direction: column;
            padding-top: 18px;

            @media (min-width: 1024px) {
              padding-top: 0;
              width: 50%;
            }

            .answer {
              background: rgba(255, 125, 125, 0.1);
              padding: 4px 12px;
              margin-bottom: 8px;
              font-weight: 600;
              font-size: 14px;
              color: #FF574F;
              display: flex;
              justify-content: flex-start;
              align-items: center;
              text-align: left;

              .close {
                font-size: 12px;
                font-weight: 700;
                padding-top: 2px;
                padding-right: 10px;
              }
            }

            .more-correct {
              background: rgba(255, 255, 255, 0.2);
              padding: 4px 8px;
              margin-bottom: 8px;
              font-weight: 600;
              font-size: 14px;
              color: #D2D2D2;
              display: flex;
              justify-content: flex-start;
              align-items: center;
              text-align: left;

              .icon-question-mark {
                padding-right: 6px;
              }
            }

            .related-episode {
              font-weight: 400;
              font-size: 14px;
              color: #D2D2D2;
              text-align: left;

              .ope {
                color: #76bd87 !important;
              }
              .fnb {
                color: #ff6600 !important;
              }
              .snm {
                color: #ff7dd7 !important;
              }
            }


          }
        }
      }

      .help-actions-container {
        margin: 24px auto;
        display: flex;
        justify-content: space-between;

        @media (min-width: 1024px) {
          justify-content: center;          
        }

        .learn-serie-btn {
          width: 219px;
          background: #76BD87;
          border-radius: 6px;
          padding: 10px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-weight: 700;
          font-size: 14px;
          text-transform: uppercase;
          cursor: pointer;
          white-space: nowrap;
          text-decoration: none;
          color: #fff;

          .icon {
            font-size: 19px;
            @media (min-width: 1024px) {
              padding-right: 8px;
            }
          }

          @media (min-width: 1024px) {
            width: 243px;
            padding: 10px 0;
            margin: 0 2%;
          }
        }
  
        .retake-btn {
          width: 97px;
          background: #2672EC;
          border-radius: 6px;
          padding: 10px;
          display: block;
          font-weight: 700;
          font-size: 14px;
          text-transform: uppercase;
          cursor: pointer;

          @media (min-width: 1024px) {
            width: 243px;
            margin: 0 2%;
          }
        }
      }

      
    }
  }
  
}
